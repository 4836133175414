import VaultApi from 'lib/api/VaultApi'
import { useMutation, useQueryClient } from 'react-query'

interface UseDeleteCardMutationProps {
  cardId: string
}

const fetchDeleteCard = async ({ cardId }: UseDeleteCardMutationProps) =>
  VaultApi.doRequest({
    method: 'DELETE',
    url: `/v2/market/cards/${cardId}`,
  })

export const useDeleteCardMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(fetchDeleteCard, {
    onSettled: () => queryClient.invalidateQueries('cards'),
  })
}
