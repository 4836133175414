import VaultApi from 'lib/api/VaultApi'
import { ServiceError } from 'lib/api/ServiceError'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { OpenlootNotification } from 'types/vault/v2/OpenlootNotification'

const fetchOpenlootNotifications = (): Promise<OpenlootNotification[]> =>
  VaultApi.doRequest({
    method: 'GET',
    url: '/v2/market/openloot-notifications',
  })
    .then((res) => res.json())
    .catch((e: ServiceError) => {
      if (e.code === 401) {
        return null
      }

      throw e
    })

const ackOpenlootNotification = (notificationId: string): Promise<Response> =>
  VaultApi.doRequest({
    method: 'PATCH',
    url: `/v2/market/openloot-notifications/${notificationId}/ack`,
  })

export const useOpenlootNotifications = (options?: any) =>
  useQuery(['openloot-notifications'], fetchOpenlootNotifications, options)

export const useAckOpenlootNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(ackOpenlootNotification, {
    onSuccess: () => queryClient.invalidateQueries(['openloot-notifications']),
    onMutate: async (notificationId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['openloot-notifications'] })

      // Snapshot the previous value
      const previousNotifications = queryClient.getQueryData([
        'openloot-notifications',
      ]) as OpenlootNotification[]

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['openloot-notifications'],
        (prev: OpenlootNotification[] | undefined) =>
          prev ? prev.filter((notif) => notif.id !== notificationId) : []
      )

      // Return a context object with the snapshotted value
      return { previousNotifications }
    },
  })
}
