import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useUser } from 'hooks/useUser'
import { useToast } from 'hooks/useToast'
import { useEffect, useState } from 'react'
import { AppValidation } from './AppValidation'
import { PhoneVerificationModal } from './PhoneVerificationModal'
import { Flow } from './types'
import { useValidateTwoFactor } from '../../../hooks/useValidateTwoFactorMutation'
import { EnterRecoveryCodeModal } from './EnterRecoveryCodeModal'
import { TwoFactorModalContent } from './TwoFactorModalContent'
import { EmailVerificationModal } from './EmailVerificationModal'

export interface ResetTwoFactorModalProps {
  isOpen: boolean
  onClose: () => void
  flow: Flow
  availableFlows: Flow[]
  removeType: Flow
}

/**
 * The modal that is shown when the user wants to reset their two factor
 *
 * @param onClose - Called when the modal is closed
 * @param isOpen - Whether the modal is open
 * @param flow - Phone or app, the factor to remove
 * @returns The modal
 */
export const ResetTwoFactorModal: React.FC<ResetTwoFactorModalProps> = ({
  onClose,
  isOpen,
  removeType,
  flow: flowProp,
  availableFlows,
}: ResetTwoFactorModalProps) => {
  const { data: user } = useUser()
  const toast = useToast()
  const {
    isOpen: isTwoFactorResetOpen,
    onClose: onTwoFactorResetClose,
    onOpen: onTwoFactorResetOpen,
  } = useDisclosure()
  const [flow, setFlow] = useState<Flow | null>(flowProp)

  const onVerificationClose = () => {
    onTwoFactorResetClose()
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      setFlow(flowProp)
    }
  }, [isOpen, flowProp])

  if (!user) {
    return null
  }
  const twoFactorName = {
    // [0] success message; [1] confirmation message; [2] modal title
    app: ['Authenticator App', 'an authenticator app', 'Authenticator'],
    phone: ['Phone number', 'a phone verification', 'Phone'],
    email: ['Email', 'a mail verification', 'Email'],
    recovery: ['Recovery codes', 'recovery codes', 'Recovery Codes'],
    'reset-pending': [],
  }
  const successMessage = `${twoFactorName[removeType][0]} removed successfully.`

  return (
    <>
      {flow === 'phone' && (
        <PhoneVerificationModal
          isSetupAllowed={false}
          user={user}
          onVerified={() => {
            onVerificationClose()
            toast({
              title: successMessage,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          }}
          onClose={onVerificationClose}
          verificationMutation={useValidateTwoFactor}
          availableFlows={availableFlows}
          flow="phone"
          setFlow={setFlow}
          isToRemove2FA
          removeType={removeType}
          isOpen={isTwoFactorResetOpen}
        />
      )}
      {flow === 'app' && (
        <AppValidation
          isSetupAllowed={false}
          skippable={false}
          onVerified={() => {
            onVerificationClose()
            toast({
              title: successMessage,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          }}
          isOpen={isTwoFactorResetOpen}
          onClose={onVerificationClose}
          validationMutation={useValidateTwoFactor}
          isToRemove2FA
          setFlow={setFlow}
          removeType={removeType}
          availableFlows={availableFlows}
          flow={flow}
          user={user}
        />
      )}
      {flow === 'email' && (
        <EmailVerificationModal
          isSetupAllowed={false}
          user={user}
          onVerified={() => {
            onVerificationClose()
            toast({
              title: successMessage,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          }}
          onClose={onVerificationClose}
          availableFlows={availableFlows}
          flow="email"
          setFlow={setFlow}
          isToRemove2FA
          removeType={removeType}
          isOpen={isTwoFactorResetOpen}
        />
      )}
      {flow === 'recovery' && (
        <EnterRecoveryCodeModal
          skippable={false}
          onVerified={() => {
            onVerificationClose()
            toast({
              title: successMessage,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          }}
          onClose={onVerificationClose}
          validationMutation={useValidateTwoFactor}
          isToRemove2FA
          setFlow={setFlow}
          removeType={removeType}
          availableFlows={availableFlows}
          flow={flow}
          isOpen={isTwoFactorResetOpen}
          user={user}
        />
      )}

      <TwoFactorModalContent
        isOpen={isOpen}
        onClose={onClose}
        description={`Remove ${twoFactorName[removeType][2]} Verification`}
        mobileMarginTop={{ base: '174px', md: '0' }}
        desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
        innerContent={
          <Flex my="27px" direction="column">
            {removeType === 'phone' && (
              <Text fontWeight={400} fontFamily="Bai Jamjuree" fontSize="0.99rem">
                This will remove your ability to verify with a phone verification. Please note that
                Open Loot no longer supports the creation of new Phone Verification 2FA. If you
                remove your Phone Verification, you will not be able to set it up again, and will be
                limited to only Authenticator Verification and Email Verification.
              </Text>
            )}
            {removeType !== 'phone' && (
              <Text fontWeight={400} fontFamily="Bai Jamjuree" fontSize="0.99rem">
                This will remove your ability to verify with {twoFactorName[removeType][1]}.
              </Text>
            )}
            <Text fontFamily="Bai Jamjuree" fontWeight={700}>
              Are you sure you want to proceed?
            </Text>
          </Flex>
        }
        buttons={
          <Flex flexDirection="row" alignItems="center" justifyContent="center" mt={5} width="full">
            <Button
              variant="light"
              maxW={{ base: '100%', md: '50%' }}
              onClick={onTwoFactorResetOpen}
              flex={1}
              ml={1}
            >
              Continue
            </Button>
          </Flex>
        }
      />
    </>
  )
}
