import { useEffect, useState } from 'react'
import { User } from 'types/vault/User'
import { EmailVerificationModal } from './EmailVerificationModal'
import { TwoFactorFlowProps } from './types'
import { EmailSetupModal } from './EmailSetupModal'

export interface EmailTwoFactorModalProps {
  allowSetup?: boolean
  onClose: () => void
  onVerified?: () => void
  onSwitch: () => void
  skippable?: boolean
  user: User
}

export const EmailTwoFactorModal = ({ allowSetup = false, user, ...props }: TwoFactorFlowProps) => {
  const [step, setStep] = useState<'setup' | 'verify'>('verify')
  useEffect(() => {
    if ((user && !user.email2faVerified) || allowSetup) {
      setStep('setup')
    }
  }, [user, setStep, allowSetup])

  const isSetupAllowed = allowSetup || user?.email2faVerified !== 'verified'

  return (
    <>
      {step === 'setup' && (
        <EmailSetupModal
          onSuccess={() => {
            setStep('verify')
          }}
          {...props}
        />
      )}
      {step === 'verify' && (
        <EmailVerificationModal
          isSetupAllowed={isSetupAllowed}
          onBack={() => {
            setStep('setup')
          }}
          user={user}
          {...props}
        />
      )}
    </>
  )
}
