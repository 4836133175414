export enum CurrencyId {
  ETH = 'ETH',
  USD = 'USD',
  USDC = 'USDC',
  BTC = 'BTC',
  MANA = 'MANA',
  MATIC = 'MATIC',
  OPENLOOT = 'OPENLOOT',
  OL = 'OL',
}
