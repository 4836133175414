import { ComponentStyleConfig } from '@chakra-ui/react'

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    fontSize: 'md',
    lineHeight: '1.5rem',
    borderRadius: 'unset',
    h: 'auto',
  },
  sizes: {
    auto: {
      py: 'unset',
      w: 'auto',
    },
    xs: {
      py: '7px',
      w: '95px',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      h: 'auto',
    },
    sm: {
      py: '7px',
      // 170px
      w: '170px',
      // override chakra sizes
      fontSize: 'md',
      lineHeight: '1.5rem',
      h: 'auto',
    },
    normal: {
      py: {
        base: '7px',
        lg: 2.5,
      },
      // 213px
      w: '13.3125rem',
    },
    full: {
      py: '7px',
      w: '100%',
    },
    md: {
      py: {
        base: 2.5,
        lg: '15px',
      },
      w: {
        base: '13rem',
        // 306px
        lg: '19.125rem',
      },
      // override chakra sizes
      h: 'auto',
      fontSize: '1rem', // 30px
      lineHeight: '1.5rem', // 36px
    },
    lg: {
      py: 4,
      // 338px
      w: '21.125rem',
      // override chakra sizes
      h: 'auto',
      fontSize: '1.875rem', // 30px
      lineHeight: '2.25rem', // 36px
    },
    responsive: {
      py: {
        base: '12px',
        lg: '7px',
      },
      w: 'full',
      // 213px
      maxW: {
        base: 'unset',
        md: '10.625rem',
        lg: '13.3125rem',
      },
    },
    'responsive-border': {
      py: {
        base: '11px',
        lg: '6px',
      },
      w: 'full',
      // 213px
      maxW: {
        base: 'unset',
        md: '10.625rem',
        lg: '13.3125rem',
      },
    },
  },
  variants: {
    none: {
      bg: 'none',
    },
    primary: {
      bg: 'highlight.default',
      color: 'white',
      _hover: {
        bg: 'highlight.dark',
        _disabled: {
          bg: 'highlight.default', // no hover if disabled
        },
      },
    },
    'primary-normal': {
      bg: 'none',
      color: 'highlight.default',
      _hover: {
        color: 'highlight.700',
      },
    },
    dark: {
      bg: 'gray.800',
      color: 'whiteAlpha.300',
      _disabled: {
        opacity: 1,
      },
      _hover: {
        _disabled: {
          bg: 'gray.800',
        },
      },
    },
    light: {
      bg: 'white',
      color: 'gray.800',
      _hover: {
        color: 'text-cards',
        bg: 'whiteAlpha.700',
        // no effect on disable hover
        _disabled: {
          color: 'gray.800',
          bg: 'white',
        },
      },
    },
    'light-outline': {
      border: '1px solid white',
      color: 'white',
      _hover: {
        bg: 'whiteAlpha.200',
      },
    },
    'light-outline-muted': {
      border: '1px solid white',
      color: 'white',
      backgroundColor: 'gray.400',
      _hover: {
        bg: 'whiteAlpha.200',
      },
    },
    'light-normal': {
      fontSize: 'sm',
      fontWeight: 'normal',
      color: 'white',
      textDecoration: 'underline',
      _hover: {
        fontWeight: 'bold',
      },
    },
    'link-cancel': {
      color: 'white',
      fontSize: '14px',
      fontWeight: '300',
      textDecoration: 'underline',
    },
    highlight: {
      bg: 'highlight.default',
      color: 'white',
      fontStyle: 'bold',
      fontWeight: '700',
      textDecoration: 'none',
      _hover: {
        color: 'white',
        bg: 'highlight.dark',
        _disabled: {
          color: 'whiteAlpha.700',
          bg: 'highlight.dark',
        },
      },
    },
    'highlight-hover': {
      bg: 'white',
      color: 'gray.800',
      _hover: {
        color: 'white',
        bg: 'highlight.default',
        _disabled: {
          color: 'text-cards',
          bg: 'whiteAlpha.700',
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'normal',
  },
}

export default Button
