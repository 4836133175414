import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'
import { GiveawayConditionPassedCheck } from '../../types/vault/v2/Giveaway'

interface UserSignedResponse {
  signedUp: boolean
}

export const fetchIsUserSignedForExternalGame = (slug: string): Promise<UserSignedResponse> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/signup-external/${slug}/`,
  }).then((res) => res.json())

export const useIsUserSignedUpForExternalGame = (slug: string, options?: any) =>
  useQuery(
    ['signups-external-user', { slug }],
    () => fetchIsUserSignedForExternalGame(slug),
    options
  )
