import { SaleItemCard } from '../../organisms/sales/SaleItemCard'
import { SaleItemCardFullImg } from '../../organisms/sales/SaleItemCardFullImg'
import { BoxSale } from '../../../types/vault/v2/Sale'

interface SaleItemProps {
  sale: BoxSale
  slug: string
  isFullImage: boolean
  gameName: string
}

export const SaleItem = ({ sale, slug, isFullImage, gameName }: SaleItemProps) => {
  let itemMetadata
  if (sale.product) {
    itemMetadata = sale.product.slotsMetadata[0][0].itemMetadata
  }
  if (isFullImage) {
    return <SaleItemCardFullImg key={sale.id} sale={sale} itemMetadata={itemMetadata} slug={slug} />
  }
  return (
    <SaleItemCard
      key={sale.id}
      sale={sale}
      itemMetadata={itemMetadata}
      slug={slug}
      gameName={gameName}
    />
  )
}
