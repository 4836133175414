import { User } from 'types/vault/User'

export type Flow = 'phone' | 'email' | 'app' | 'recovery' | 'reset-pending'

export interface TwoFactorModalProps {
  allowSetup?: boolean
  isToRemove2FA?: boolean // if true, the modal is purposed to remove 2FA
  isOpen: boolean
  onClose: () => void
  onVerified?: () => void
  skippable?: boolean
  user: User
  flow: Flow | undefined
  availableFlows: Flow[]
  removeType?: Flow
  skipOnCloseWhenSucceed?: boolean
}

export interface TwoFactorFlowProps extends TwoFactorModalProps {
  setFlow: (f: Flow | null) => void
  setRecoveryCodes?: (recoveryCodes: string[]) => void
}

export function getAvailableFlowsFromUser(user: undefined | User | null) {
  if (!user) return { flow: undefined, availableFlows: [] }

  const flows: Flow[] = []
  if (user.totpVerified === 'reset-pending' || user.phoneNumberStatus === 'reset-pending') {
    flows.push('reset-pending')
  } else {
    if (user.totpVerified === 'verified') flows.push('app')
    if (user.email2faVerified === 'verified') flows.push('email')
    if (user.phoneNumberStatus === 'validated') flows.push('phone')
    if (user.recoveryCodesGenerated) flows.push('recovery')
  }

  if (flows.length === 0) {
    return { flow: undefined, availableFlows: [] }
  }
  return { flow: flows[0], availableFlows: flows }
}

export type FlowProperties = {
  flow: Flow | undefined
  availableFlows: Flow[]
}
