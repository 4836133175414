import AuthApi from 'lib/api/AuthApi'
import useSWR from 'swr'
import { LinkedAccount, User } from '../types/vault/User'

const defaultProviders = ['google', 'discord', 'twitch']

export const linkAccountUrl = '/v2/auth/linked-accounts'

export const useLinkedAccounts = (user: User | null | undefined) =>
  useSWR<LinkedAccount[]>(
    user ? linkAccountUrl : null, // this avoids fetch when user is not logged in
    (url) => AuthApi.doRequest({ method: 'GET', url }).then((res) => res.json()),
    {
      fallbackData: defaultProviders.map(
        (provider) => ({ provider, externalAccount: null } as LinkedAccount)
      ),
      isOnline: () => Boolean(user),
    }
  )
