import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'

export const fetchIsUnder18User = () =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/users/is-under-18`,
  }).then((res) => res.json())

export const useIsUnder18User = ({ enabled = false }) =>
  useQuery('is-under-18-user', () => fetchIsUnder18User(), {
    enabled,
    retry: (failureCount, err: any) => err?.code !== 400 && err?.code !== 401 && failureCount < 3,
  })
