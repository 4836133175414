import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'

const fetchEmailVerification = ({ email }: { email: string }): Promise<Response> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/v2/auth/2fa/email/register',
    body: {
      email,
    },
  })

export const useEmailVerificationSetupMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(fetchEmailVerification, {
    onSuccess: () => queryClient.invalidateQueries(['me']),
  })
}
