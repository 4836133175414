import { useFeaturesAccessibility } from 'hooks/useFeatureAccessibility'
import { features } from 'types/vault/Config'
import FourOhFour from '../../../pages/404'

interface Props {
  children: JSX.Element
  features: features[]
  redirectFourOhFour?: boolean
}

export const RequireFeaturesAccessible = ({
  children,
  features,
  redirectFourOhFour = true,
}: Props) => {
  const queries = useFeaturesAccessibility(features)

  const isAccessible = queries.every((query) => query.data === true)

  if (isAccessible) {
    return children
  }

  const allLoadedOrError = queries.every(
    (query) => query.status === 'success' || query.status === 'error'
  )

  if (redirectFourOhFour && allLoadedOrError) {
    return <FourOhFour />
  }

  return <></>
}
