import { HStack } from '@chakra-ui/react'
import { ChildrenProp } from 'types/globals'

export const BorderTopBox: React.FC<ChildrenProp & { color?: string }> = ({ children, color }) => (
  <HStack
    borderTop="1px"
    borderColor={{
      base: 'transparent',
      md: color ?? 'whiteAlpha.400',
    }}
    pt={{ base: 0, md: 5 }}
    spacing={{ base: 2.5, md: 4, lg: '18px' }}
    justifyContent="space-between"
  >
    {children}
  </HStack>
)
