import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'
import { ServiceError } from '../lib/api/ServiceError'

interface HOTPAuthPayload {
  token: string
}

interface HOTPValidateResponse {
  status: boolean
  recoveryCodes: string[]
}

const validateEmailTwoFactor = ({ token }: HOTPAuthPayload): Promise<HOTPValidateResponse> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/v2/auth/2fa/email/validate',
    body: {
      token,
    },
  }).then((response) => response.json())

export const useValidateEmailTwoFactorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<HOTPValidateResponse, ServiceError, HOTPAuthPayload>(validateEmailTwoFactor, {
    onSettled: () => {
      queryClient.invalidateQueries('me')
    },
  })
}
