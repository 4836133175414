import { Item } from 'types/vault/v2/Item'

export const isRedeemable = (item?: Item): boolean => {
  if (!item) {
    return false
  }

  if (!item.redeemable) {
    return false
  }

  return true
}

export const canBeRedeemable = (item?: Item): boolean => {
  if (!item) {
    return false
  }

  if (!item.redeemable) {
    return false
  }

  if (item.redeemableAt) {
    const now = new Date().getTime()
    return now > new Date(item.redeemableAt).getTime()
  }

  return true
}

export const getCollectionAndOptionFromArchetypeId = (archetype: string) => {
  const collection = archetype.split('_')[0]
  const optionName = archetype.slice(collection.length + 1)

  return { collection, optionName }
}
