import { Box, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { formatDate } from 'lib/format'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { Breadcrumb, Button } from '../atoms'
import { Container } from './layout'

export const TermsOfUse = () => (
  <>
    <Head>
      <title key="title">{formatHtmlTitle('Terms of Use')}</title>
    </Head>
    <Container
      mt={{ base: '18px', md: '22px' }}
      pb="77px"
      baseMaxWidth="1280px"
      extraPaddingX="2px"
    >
      <Breadcrumb items={[{ label: 'Terms of Use', href: '/terms-of-use' }]} />
      <Text display="inline-block" mt={3} borderBottom="1px solid white">
        Last updated: {formatDate(new Date(2023, 11, 19))}
      </Text>
      <Heading color="white" fontSize={['30px', '40px', '50px']} mb={8} mt={6}>
        Terms of Use
      </Heading>
      <Box overflowY="auto" maxH="58vh">
        <Stack spacing={3} fontSize="xs" fontWeight="500" pb="30px">
          <Text>1. ACCEPTANCE OF TERMS</Text>
          <Text>
            Open Loot LLC (“OL”) provides the website{' '}
            <Link href="www.openloot.com" target="_blank" textDecor="underline">
              www.openloot.com
            </Link>{' '}
            (the “Site”), and the service of the Open Loot Vault (the “Vault”), the Marketplace (the
            “Marketplace”) and the Premium Currency Marketplace (the “Premium Currency Marketplace”,
            jointly, the Site, the Vault, the Marketplace and the Premium Currency Marketplace, the
            “Services”). Please read these Terms of Use (the “Terms” or “Terms of Use”) carefully
            before using the Services. By using or otherwise accessing the Services, you: (1) accept
            and agree to be bound by these Terms; (2) accept that the Services are still in testing
            phase and that you use at your own risk, as further explained in Section 2 below; (3)
            represent that you are old enough to use and access the Services pursuant to Sections 3
            and 8 below; (4) consent to the collection, use, disclosure and other handling of
            information as described in our Privacy Policy, available at{' '}
            <Link href="/privacy-policy" target="_blank" textDecor="underline">
              https://openloot.com/privacy-policy
            </Link>
            , the terms of which are incorporated herein in their entirety, and (5) accept and agree
            to any additional terms, rules and conditions of participation issued by OL from time to
            time. If you do not agree to the Terms, then you must not access or use the Services. OL
            has no continued obligation to provide the Services in the future, at its exclusive
            discretion, with no liability whatsoever in connection thereto.
          </Text>
          <Text>2. TESTING DISCLAIMER AND MODIFICATION OF TERMS OF USE</Text>
          <Text>
            The Marketplace and the Vault are still in testing phase and are provided on an “as is”
            and “as available” basis and may contain defects and software bugs. You are advised to
            safeguard important data, property and content, to use caution, and to not rely in any
            way on the correct or secure functionality or performance of the Marketplace and the
            Vault. Except for Section 17, providing for binding arbitration and waiver of class
            action rights, OL reserves the right, at its sole discretion, to modify or replace the
            Terms of Use at any time. The most current version of these Terms will be posted on our
            Site. You shall be responsible for reviewing and becoming familiar with any such
            modifications. Use of the Marketplace and the Vault by you after any modification to the
            Terms constitutes your acceptance of the Terms of Use as modified.
          </Text>
          <Text>
            The Marketplace and the Vault may be updated from time to time, in order to, among
            others, improve system security, stability, interoperability, enhance usability, etc.,
            which may result in changing system requirements. You consent to these updates, although
            you understand that OL does not undertake to make any such updates in the future, which
            may be provided at OL’s sole discretion.
          </Text>
          <Text>3. ELIGIBILITY</Text>
          <Text>
            You hereby represent and warrant that you are fully able and competent to enter into the
            terms, conditions, obligations, affirmations, representations and warranties set forth
            in these Terms and to abide by and comply with these Terms. OL operates a global
            platform and by accessing the Services, you are representing and warranting that you are
            13 years old or older.
          </Text>
          <Text>
            If you are aged between 13 and 18 years old you will only have access to the Vault and
            will not have access to the Marketplace or the Premium Currency Marketplace (i.e., no
            access to purchases, sales, rentals or withdrawals of NFTs o Premium Currencies nor
            top-up of Marketplace Credits).
          </Text>
          <Text>
            Access to certain Games (as this term is defined below) may require a higher age. When
            accessing such Games you represent and warrant that you comply with the applicable age
            requirements.
          </Text>
          <Text>4. ACCOUNT ACCESS AND SECURITY</Text>
          <Text>
            4.1. For accessing the Services you need to create an account (the “Marketplace
            Account”), with a user name and a password. You can only have one Marketplace Account.
            The Marketplace Account also provides access to the third-party games available from
            time to time (the “Games”). You authorize OL to share your personal information with the
            entities who own or operate each of the Games (the “Developers”) that can be accessed
            with your Marketplace Account. The use of such games is governed by the respective terms
            of use and privacy policies issued by each Developer.
          </Text>
          <Text>
            4.2. For the creation of the Marketplace Account and/or in connection with certain
            features that may be offered through the Marketplace, such as transactions involving the
            receipt, processing or transfer of any financial consideration, you will be required to
            provide personal information, including your name, photo or other identification (e.g.,
            driver’s license), and other personal information detailed in our Privacy Policy, and as
            detailed in Section 4.1. hereto to verify your identity. OL may, in its sole discretion,
            refuse to open a Marketplace Account or restrict your access to certain features until
            OL is able to verify your identity. You agree to provide the information we request for
            the purposes of identity verification and the detection of money laundering, terrorist
            financing, fraud, or any other financial crime and permit OL to keep a record of such
            information for as long as is legally permissible. The access to one or more features
            and the limits that apply to your use of them may be altered as a result of information
            collected about you on an ongoing basis. The information may include certain personal
            information, including, but not limited to, your name, address, telephone number, e-mail
            address, date of birth, taxpayer identification number, a government identification, and
            information regarding your bank account (such as the name of the bank, the account type,
            routing number, and account number) as detailed in our Privacy Policy. In providing OL
            with this or any other information that may be required, you represent that the
            information is accurate and authentic, and you agree to keep the information updated if
            there are changes.
          </Text>
          <Text>
            4.3 You authorize OL to make inquiries, whether directly or through third parties, that
            are considered necessary to verify your identity or protect you and/or OL against fraud
            or other financial crime, and to take action we reasonably deem necessary based on the
            results of such inquiries. When OL carries out these inquiries, you acknowledge and
            agree that your personal information may be disclosed to credit reference and fraud
            prevention or financial crime or other law enforcement agencies and that these agencies
            may respond to our inquiries in full. This is an identity check only and should have no
            adverse effect on your credit rating. Additionally, OL may require you to wait some
            amount of time after completion of a transaction, before permitting you to use further
            features and/or before permitting you to engage in transactions beyond certain volume,
            currency or financial limits, all as established by OL in its sole discretion.
          </Text>
          <Text>4.4 Restricted Persons.</Text>
          <Text>
            OL, in its sole discretion, will determine which persons or entities with whom it will
            establish or maintain relationships, or may use or access the Services such as the
            Marketplace and Vault. OL will not establish or maintain relationships, or accept funds
            from, or on behalf of, any person or entity if they:
          </Text>
          <Text>● Cannot be identified in accordance with OL’s KYC standards and AML program;</Text>
          <Text>● Are unresponsive to information requested by OL;</Text>
          <Text>● Provide misleading or inaccurate information to OL;</Text>
          <Text>● Are known sanctioned individuals or entities;</Text>
          <Text>● Are identified as a known criminal and/or criminal associates; and</Text>
          <Text as="u">● Are from the following jurisdictions:</Text>
          <Text>
            Afghanistan ● Central African Republic ● Democratic Republic of the Congo ● Cuba ●
            Guinea-Bissau ● Islamic Republic of Iran ● Iraq ● Democratic People's Republic of Korea
            ● Libya ● Mali ● Russia ● Somalia ● South Sudan ● Sudan ● Syrian Arab Republic ● Ukraine
            ● Bolivarian Republic of Venezuela ● Yemen ● Belarus ● Myanmar
          </Text>
          <Text>
            Entities incorporated, or individuals residing in, any of the following states: Alaska,
            Hawaii, Minnesota or New York will not have access to USDC, or be able to carry out USDC
            transfers. You agree that you will not use IP proxying or other methods to disguise the
            place of your residence, whether to circumvent geographical restrictions on game
            content, to purchase at pricing not applicable to your geography, or for any other
            purpose. If you do this, OL may terminate your access to your Marketplace Account.
          </Text>
          <Text>4.5 Restricted Activities</Text>
          <Text>
            Individuals related with the following activities shall not be able to open Marketplace
            Accounts, and the Marketplace Accounts cannot be used in connection with the following
            activities:
          </Text>
          <Text>
            ● Illegal weapons of any kind in the applicable jurisdiction, including but not limited
            to certain firearms, ammunition, knives, explosives, or related accessories ● Controlled
            substances, including but not limited to narcotics, prescription drugs, steroids, or
            related paraphernalia or accessories ● Prostitution ● Gambling activities including but
            not limited to sports betting, casino games, horse racing, dog racing, lotteries, games
            of chance, sweepstakes, games of skill that may be classified as gambling (i.e. poker),
            or other activities that facilitate any of the foregoing ● Money-laundering or terrorist
            financing ● Any sort of Ponzi scheme, pyramid scheme, or multi-level marketing program ●
            Goods or services that infringe or violate any copyright, trademark, or proprietary
            rights under the laws of any jurisdiction ● Debt settlement, refinance, or credit repair
            services ● Court-ordered tax payments, or tax settlements ● Lottery contracts, layaway
            systems, or annuities ● Counterfeit goods, including but not limited to fake or
            “novelty” IDs ● Wash trading, front-running, insider trading, market manipulation or
            other forms of market-based fraud or deceit ● Purchasing goods of any type from Tor
            hidden service markets or “Darknet” markets, or any other service or website that acts
            as a marketplace for illegal goods (even though such marketplace might also sell legal
            goods) ● Transactions involving sanctioned parties, countries or coins; ● Any other
            activity that is against applicable laws and regulations of countries in which OL
            operates.
          </Text>
          <Text>4.6 KYC</Text>
          <Text>
            To help the government fight the funding of terrorism and money laundering activities,
            U.S. and other countries’ laws require OL to obtain, verify, and record information that
            identifies each person who opens an account. What this means for you: When you open a
            Marketplace Account we may collect your name, password, and other personal information
            as detailed in our Privacy Policy. For the Marketplace, in accordance with OL’s Bank
            Secrecy Act / Anti-Money Laundering and OFAC Compliance Program Manual, and our Privacy
            Policies we may collect the following personal information from you: If you are an
            individual: • Copy of your driver’s license or other identifying documents • Full legal
            name • Correct permanent address including postcode (if appropriate) • Date and place of
            birth • Nationality • Identification number • Occupation • Purpose/nature of the
            intended business • The source of funds (i.e., generated from a transaction or business)
            • Copy of a document that establishes your identity (e.g. Passport, Driver’s License) •
            A selfie
            <br />
            If you are an entity: • Full legal entity name • Doing business as (“DBA”) name (if
            different) • State and country of formation • Date of formation • Tax ID / registration
            number • Number of employees • Link to business website • Nature of business • Physical
            business address • Information about the authorized representative such as: (i) passport
            for personal information; and (ii) any document that evidence the incumbency of the
            representative • Information about each ultimate beneficial owner that directly or
            indirectly owns at least 20% of the entity such as: (i) passport; (ii) proof of address;
            and (iii) source of wealth declaration. Open Loot will also collect information related
            to the title of the beneficial owner's identity and his/her percentage of ownership if
            it is above 20 % • Personal information about all directors and at least a member of
            Senior Management (e.g., Chief Executive Officer, Chief Operating Officer) of the
            company (such as Full Name, ID/Passport No., date of birth, address and information
            related to titles of director).
            <br />
            Additional requirements may apply as per KYC requirements and the type of entity.
          </Text>
          <Text>KYC/Customer Due Diligence will be mandatory in the following cases:</Text>
          <Text>
            ● Customer is an individual or entity whose account is “open loop”, i.e., the wallet can
            hold USD Coin (“USDC”) and have on-chain access.
          </Text>
          <Text>
            ● Customer is an individual or entity whose account is “closed loop”, and has reached
            either of the following thresholds:
            <br />
            - If within any twenty-four (24) hour period, the total amount stored in your
            Marketplace Account exceeds US$2,000 or its equivalent in your local currency.
            <br />
            - Before making card or wire transfer payments in excess of US$3,000 or its equivalent
            in your local currency.
            <br />
            - If the aggregate amounts deposited over the account’s lifetime have US$ 10,000 or its
            equivalent in your local currency.
            <br />
            - Before making withdrawals from the account.
            <br />- If your credit card has reached one of the following purchase limits: US$ 3,000
            daily (or its equivalent in your local currency); US$ 5,000 weekly (or its equivalent in
            your local currency.), or US$ 20,000 monthly (or its equivalent in your local
            currency.). If your credit card limit is exceeded, you will not be able to make
            transactions in the Marketplace until you comply with KYC requirements.
          </Text>
          <Text>
            4.7 For making deposits or withdrawals from the Vault you need a third party private key
            manager selected by you (i.e., a Web3 Provider such as Fortmatic). You and the third
            party private key manager you select are entirely responsible for security of the
            withdrawals and deposits made into and out of the Vault.
          </Text>
          <Text>
            4.8 OL bears no responsibility for any breach of security or unauthorized access to your
            Marketplace Account. You are advised to: (a) avoid any use of the same password with
            your selected third party private key manager that you have ever used outside of the
            third party private key manager; and (b) keep your password and any related secret
            information secure and confidential and do not share them with anyone else.
          </Text>
          <Text>
            4.9 You are solely responsible for all activities conducted through your Marketplace
            Account whether or not you authorize the activity. In the event that fraud, illegality
            or other conduct that violates this Agreement is discovered or reported (whether by you
            or someone else) that is connected with your Marketplace Account, OL may terminate your
            Marketplace Account (or Marketplace Accounts) as described in Section 14, and we may
            report you to, and will cooperate with, legal authorities.
          </Text>
          <Text>
            4.10 You are solely responsible for maintaining the confidentiality of your password and
            for restricting access to your devices. You are solely responsible for any harm
            resulting from your disclosure, or authorization of the disclosure, of your password or
            from any person's use of your password to gain access to your Marketplace Account.
          </Text>
          <Text>
            4.11 Any loss or compromise of your electronic device or your security details may
            result in unauthorized access to your Marketplace Account by third-parties and the loss
            or theft of any NFT held in the Vault. You must keep your security details safe at all
            times.
          </Text>
          <Text>
            4.12 You must immediately notify OL of any unauthorized use of, or access to, your
            Marketplace Account, password, or other breach of security at{' '}
            <Link href="mailto:admin@openloot.com" textDecor="underline">
              admin@openloot.com.
            </Link>
            .
          </Text>
          <Text>
            4.13 Please note that if you lose the password of your third party private key manager,
            you accept that OL will be unable to remedy any issues that arise from that incident.
          </Text>
          <Text>
            4.14 OL will not be liable for any loss or damage (of any kind and under any legal
            theory) to you or any third party arising from your inability or failure for any reason
            to comply with any of the foregoing obligations, or for any reason whatsoever, except
            fraud on our part.
          </Text>
          <Text>
            4.15 OL cannot and will not be liable for any loss or damage arising from your sharing
            or other loss of your password, private key or related information, or any other damage
            or loss arising from unauthorized access to your Marketplace Account.
          </Text>
          <Text>5. REPRESENTATIONS AND RISKS</Text>
          <Text>5.1 DISCLAIMER.</Text>
          <Text>
            You acknowledge and agree that your use of the Services is at your own risk. The
            Services are provided on an “as is” and “as available” basis, without warranties of any
            kind, either express or implied, including, without limitation, implied warranties of
            merchantability, fitness for a particular purpose or non-infringement. You acknowledge
            and agree that OL has no control over, and no duty to take any action regarding: which
            users gain access to the Services; what effects the Services may have on you; how you
            may interpret or use the Services; or what actions you may take or fail to take as a
            result of having been exposed to the Services. You release OL from all liability for
            your inability to access the Services or any content therein.
          </Text>
          <Text>5.2 RISK OF REGULATORY ACTIONS IN ONE OR MORE JURISDICTIONS.</Text>
          <Text>
            For using the Vault, you will need to use blockchain tokens supported by the Vault.
            Blockchain tokens could be impacted by one or more regulatory inquiries or regulatory
            action, which could impede or limit the ability of OL to continue to develop, or which
            could impede or limit your ability to access or use the Marketplace, the Vault, or
            blockchain.
          </Text>
          <Text>5.3 APPLICATION SECURITY.</Text>
          <Text>
            You acknowledge and agree that the Services and related applications are software code
            and are subject to flaws and acknowledge that you are solely responsible for evaluating
            any smart contract or other code provided by the Services, or content and the
            trustworthiness of any third-party websites, products, smart-contracts, or content you
            access or use through the Services. This warning and others later provided by OL in no
            way evidence or represent an on-going duty to alert you to all of the potential risks of
            utilizing the Services.
          </Text>
          <Text>5.4 USES OF THE VAULT, THE MARKETPLACE AND THE PREMIUM CURRENCY MARKETPLACE.</Text>
          <Text>
            The Vault feature allows you to store blockchain-backed NFTs and use them in-game, as
            well as withdrawing them to your own, personally managed crypto wallet, when available.
            You acknowledge and agree that OL does not provide any representation or warranty
            regarding the value or merchantability of the NFTs, nor does OL provide any
            representation or warranty, and affirmatively disclaims any representation or warranty,
            regarding the security of the Vault feature, the purchase or trade of the NFTs, the NFTs
            themselves, the applicable blockchain, or the crypto wallet.
            <br />
            The Marketplace allows you to purchase, rent and trade NFTs. NFTs cannot be redeemed for
            money with OL. Prices in the Marketplace are freely set by the Developers and the users.
            The Developers sell NFTs in the Marketplace. Those sales are governed by each of the
            Developer’s terms of use available on their respective websites.
            <br />
            The Premium Currency Marketplace allows you to purchase and trade in-game currencies
            (the “Premium Currencies”).
            <br />
            The Developers sell Premium Currencies in the Premium Currency Marketplace. Those sales
            are governed by each of the Developer’s terms of use available on their respective
            websites.
            <br />
            The Premium Currency Marketplace allows you to trade Premium Currencies of the Games.
            Trading of Premium Currencies is only allowed in the Premium Currency Marketplace
            between players of the relevant Game and subject to the applicable terms and conditions
            of the relevant Game. Premium Currencies cannot be redeemed for money with OL, and can
            only be used for accessing content in the relevant Game. Prices in the Premium Currency
            Marketplace are freely set by users, and do not reflect any underlying value for use of
            the Premium Currency outside the relevant Games. OL does not recognize or take
            responsibility for third-party services that allow Users to sell, transfer, or otherwise
            use Premium Currencies and any such use by a User is a violation of these Terms.
          </Text>
          <Text>
            6. VAULT{' '}
            <Text as="sup" fontSize="10px">
              1
            </Text>
          </Text>
          <Text>
            6.1. All NFTs held in the Vault are custodial assets held by OL for your benefit. The
            Vault, the Marketplace and the Premium Currency Marketplace are electronic systems
            developed in and services provided from the Cayman Islands. In consequence, at the time
            of your purchase, title to, and risk of loss from, the NFTs and the Premium Currencies
            passes from the sellers to you in the Cayman Islands. Therefore, title and risk to the
            NFTs and the Premium Currencies held by you in the Vault or acquired by you on the
            Marketplace and the Premium Currency Marketplace shall at all times remain with you and
            shall not be assumed to be transferred to OL. Also, you shall bear all potential risk of
            loss of such NFTs and the Premium Currencies held by you in the Vault or acquired by you
            on the Marketplace and the Premium Currency Marketplace.
          </Text>
          <Text>
            6.2. At any time, subject to outages, downtime, force majeure events, and other
            applicable policies, you may withdraw your NFTs from the Vault by sending them to a
            different blockchain wallet controlled by you.
          </Text>
          <Text>7. MARKETPLACE AND PREMIUM CURRENCY MARKETPLACE</Text>
          <Text>
            7.1. The Marketplace Account gives you access to the Marketplace and the Premium
            Currency Marketplace. All transactions in the Marketplace and the Premium Currency
            Marketplace are performed with Marketplace Credits. The Marketplace Account is neither a
            bank account nor any kind of payment instrument. It functions as a prepaid balance to
            make transactions in the Marketplace. Based on Section 7.7 hereof, for purposes of
            calculating the prepaid balance and performing top-ups, payments and withdrawals from
            the Marketplace Account, 1 Marketplace Credit is equivalent to USDC 1 (equivalent to USD
            1).
          </Text>
          <Text>
            7.2. Subject to availability in your country (which you can check here), the Marketplace
            Account can be topped-up by various methods available at the Marketplace which you can
            check in the Marketplace at the time of use.
          </Text>
          <Text>
            7.3. Top-ups are converted into Marketplace Credits. Marketplace Credits are not
            currency. Marketplace Credits function as a prepaid balance to acquire NFTs in the
            Marketplace and/or Premium Currencies in the Premium Currency Marketplace.
          </Text>
          <Text>
            7.4. Depending on the method, due to the timing of processes of Debit Card Issuers,
            Credit Card Issuers, intervening Banks or the relevant Blockchain processing network,
            certain time might elapse from the time you give the top-up order until the Marketplace
            Credits are available in your Marketplace Account.
          </Text>
          <Text>
            7.5. Marketplace Credits can be used to acquire NFTs in the Marketplace, to trade
            Premium Currencies in the Premium Currency Marketplace or to make withdrawals from the
            Marketplace Account to a bank account (in USD) or a wallet (in USDC). If you deposit
            other crypto currencies in your Marketplace Accounts such as BTC, ETH or MANA to
            purchase Marketplace Credits in order to purchase in the Marketplace and the Premium
            Currency Marketplace, and you want to withdraw in crypto currency, the withdrawal will
            always be made in USDC. Depending on the method, due to the timing of processes of
            intervening Banks or Blockchain, certain time might elapse from the time you give the
            withdrawal order until your funds are available in your bank account or wallet.
          </Text>
          <Text>
            7.6. Depending on the transaction, due to the timing of the process, a fourteen (14) day
            window might elapse from the time you made a sale until you receive the funds in your
            Marketplace Account.
          </Text>
          <Text>
            7.7. Payment solutions are provided by Circle.
            <br />
            When you provide payment information to Circle or to one of its payment processors, you
            represent that you are the authorized user of the card, PIN, key or account associated
            with that payment, and you authorize OL and Circle to charge your credit card or to
            process your payment with the chosen third-party payment processor for any payment,
            withdrawal or fees incurred by you. OL may require you to provide your address or other
            information in order to meet its obligations under applicable laws.
            <br />
            PAYMENTS IN CRYPTOCURRENCY: CIRCLE ONLY SUPPORTS A LIMITED NUMBER OF CRYPTOCURRENCIES
            AND BLOCKCHAINS WHICH MAY CHANGE FROM TIME TO TIME. YOU CAN CHECK FROM THE MARKETPLACE
            WEBSITE AT THE TIME OF USE THE AVAILABLE CRYPTOCURRENCIES. TRANSFERS OF OTHER
            CRYPTOCURRENCIES ARE MADE AT YOUR OWN RISK, AND MAY RESULT IN THE TOTAL LOSS OF SAID
            TRANSFER. NEITHER OL, THE DEVELOPERS NOR ANY OF THEIR DIRECTORS, OFFICERS, EMPLOYEES OR
            AFFILIATES SHALL BE LIABLE IN CONNECTION THERETO.
            <br />
            In the event of a transfer of unsupported cryptocurrencies involving a minimum of US$
            200, depending on the third-party service providers' availability and recoverability, we
            may make reasonable efforts to assist you in the recovery with the assistance of
            third-party service providers. Given the nature of blockchain transactions, we do not
            guarantee that these efforts will be successful and disclaim any liability in connection
            thereto. The recovery process might take months. The recovery is subject to a 20%
            deduction or higher over the amounts recovered to cover gas fees, administrative
            expenses, and third-party service providers’ fees.
          </Text>
          <Text>
            7.8. Refunds and chargebacks.
            <br />
            Top-Ups made to the Marketplace Accounts are refundable, conditioned to your not having
            entered into any transactions in the Marketplace or in the Premium Currency Marketplace
            (i.e., not having purchased NFTs, Premium Currencies or otherwise committed any of the
            amounts in the applicable top up). Refunds can be made in Marketplace Credits in the
            balance of your Marketplace Account.
            <br />
            NOTWITHSTANDING THE FOREGOING, ALL PURCHASES OF NFTs AND PREMIUM CURRENCIES, EITHER FROM
            OL, THE DEVELOPERS OR OTHER USERS OF THE MARKETPLACE AND THE PREMIUM CURRENCY
            MARKETPLACE, ARE FINAL AND NON-REFUNDABLE, EXCEPT AS REQUIRED BY APPLICABLE LAW.
            <br />
            Please note that in the event of chargebacks due to fraud, as well as in the case of
            transactions that are otherwise returned, unpaid, or unauthorized, Open Loot shall be
            authorized to unwind purchases of NFTs directly derived from the unauthorized
            transaction. In addition, NFTs and assets to which the user (e.g., by mistake,
            unauthorized transaction) is not entitled may be removed from the Marketplace Account.
            <br />
            Open Loot may suspend your Marketplace Account while determining the existence of fraud
            in cases of chargeback. Furthermore, if Open Loot determines that fraud has taken place,
            in addition to any other legal remedy, your Marketplace Account may be permanently
            closed.
          </Text>
          <Text>8. TRANSACTIONS AND FEES</Text>
          <Text>
            8.1. You may purchase NFTs on the Marketplace directly from the Developers or other
            users and those NFTs will be stored in the Vault. Sales of NFTs by the Developers are
            governed by the respective Developer’ terms of use. At any time of your choosing,
            subject to outages, downtime, force majeure events, and other applicable policies, you
            can withdraw the NFTs from the Vault into your personal wallet. OL has no visibility
            into or control over your wallet. With that in mind, OL will have no liability to you or
            to any third party for any claims or damages that may arise as a result of any
            transactions that you engage in from your personal wallet in the Marketplace.
          </Text>
          <Text>
            8.2. You may purchase Premium Currencies on the Premium Currency Marketplace directly
            from the Developers or other users, depending on the applicable terms and conditions of
            each Developer with regards to each Premium Currency. Sales of Premium Currency by the
            Developers are governed by the respective Developer’ terms of use.
          </Text>
          <Text>
            8.3. You may rent NFTs on the Marketplace from other users and those NFTs will be stored
            in the Vault. Certain NFTs may not be available for rental. The renter of the NFTs will
            receive the in-game benefits of ownership, excluding Airdrop points which will be
            credited to the owner. While the NFTs are listed for rental, but not currently subject
            to rent, they will not be eligible for Airdrop points. While the rent is outstanding,
            NFTs subject to rental cannot (i) be listed for sale or sold by the owner or renter;
            (ii) be withdrawn to the blockchain by the owner or renter; (iii) cannot be unwrapped by
            the owner or renter; or (iv) in the case of Mystery Boxes, be redeemed by the owner or
            renter.
            <br />
            Rentals are made under a 30-day subscription system paid in advance. The subscription
            will remain active and auto-renew every 30 days unless the renter or the owner choose to
            cancel the subscription. Rental conditions such as the rented assets and the
            subscription price cannot be changed during the active subscription period.
            <br />
            You can cancel the subscription with at least three business days in advance of the
            subscription period clicking on the cancel option in your account, but the cancellation
            will only take effect upon the termination of the relevant 30-day subscription period.
            <br />
            OL will send you notices to your registered email on a regular basis, as follows: (i)
            within two business days after the date in which the subscription has been requested,
            confirming whether or not the transaction took place, and including a receipt for the
            transaction, as applicable; and (ii) three business days in advance of the expiration of
            each subscription period, so you can decide whether to cancel or renew the subscription.
          </Text>
          <Text>
            Should you detect any errors you can reach out to us using the forms available here:{' '}
            <Link
              textDecor="underline"
              href="https://openloot.zendesk.com/hc/en-us"
              target="_blank"
            >
              https://openloot.zendesk.com/hc/en-us.
            </Link>
            <br />
            YOU ARE RESPONSIBLE FOR ANY UNAUTHORIZED TRANSFERS OR SUBSCRIPTIONS MADE FROM YOUR
            ACCOUNT, AND OL SHALL BEAR NO LIABILITY IN CONNECTION THERETO.
            <br />
            Should you have any doubts regarding the cancellation of the subscriptions you can refer
            to this article{' '}
            <Link
              textDecor="underline"
              href="https://openloot.zendesk.com/hc/en-us"
              target="_blank"
            >
              https://openloot.zendesk.com/hc/en-us.
            </Link>
            <br />
            Once a subscription is ended, all rented NFTs are immediately returned to the owner. Any
            items that were placed by you in the rented SPACE will return to your inventory.
            <br />
          </Text>
          <Text>
            8.4. The Marketplace and the Premium Currency Marketplace facilitate transactions
            between the users (buyer, seller, owner and renter), but is not a party on any agreement
            between the users of NFTs or in-game currencies or between any users of the Game. In the
            case of purchases made to Developers, each Developer is responsible for compliance with
            the applicable consumer protection regulations. In the case of transactions conducted in
            the Marketplace among users, consumer protection regulations shall not apply to such
            transactions.
          </Text>
          <Text>
            8.5. All sales of NFTs in the Marketplace will be subject to a 5% fee (the “Marketplace
            Fee”) calculated using the USDC value involved in such transactions. Such Marketplace
            Fee will be borne by the participants of the transaction and deducted directly by OL
            from their Marketplace Accounts. Please be aware that transactions in third-party NFT
            marketplaces (inside or outside Open Loot) may be subject to the fees charged by said
            third-party marketplaces as per the respective terms and conditions. You agree that you
            use third-party marketplaces at your own risk, and that OL shall have no liability
            whatsoever with regards to transactions conducted in third-party marketplaces.
          </Text>
          <Text>
            8.6. All subscriptions of rental of NFTs will be subject to a 30% fee (the “Rental Fee”)
            over the subscription price calculated using the USDC value involved in such
            transactions. The Rental Fee will be deducted by OL from the subscription price.
          </Text>
          <Text>
            8.7. All transactions in the Premium Currency Marketplace will be subject to a 5 % fee
            (the “PCM Fee”) calculated using the USDC value involved in such transactions. Such PCM
            Fee will be borne by the participants of the transaction and deducted directly by OL
            from their Marketplace Accounts.
          </Text>
          <Text>
            8.8. Withdrawals from the Vault of ERC-20 tokens used in the Games (such as $BIGTIME)
            could be subject to certain withdrawal deductions set by each Developer (the “Withdrawal
            Deduction”) over the total amount withdrawn of ERC-20 tokens. The Withdrawal Deduction
            is informed prior to making the withdrawal and is delivered in full to the respective
            Developer.
          </Text>
          <Text>
            8.9. Certain blockchain network requires the payment of a transaction fee (a “Gas Fee”)
            for a certain transaction that occurs on its blockchain network, including deposits and
            withdrawals into the Marketplace. You accept that the payment of the Gas Fee is inherent
            to the nature of the blockchain network and is neither related to OL nor is a result of
            the use of the Marketplace.
          </Text>
          <Text>
            8.10. You accept that any applicable taxes in connection with the use of the Marketplace
            Account and in-game purchases shall be paid by you directly.
          </Text>
          <Text>9. CHANGES</Text>
          <Text>
            OL may make changes to these Terms from time to time. When OL makes changes, the updated
            Terms will be made available through the Site and update the “Last Updated” date at the
            beginning of these Terms accordingly. Please check these Terms periodically for changes.
            Any changes to the Terms will apply on the date that they are made, and your continued
            access to the Services after the Terms have been updated will constitute your binding
            acceptance of the updates. If you do not agree to any revised Terms, you must not access
            the Services.
          </Text>
          <Text>10. CHILDREN</Text>
          <Text>
            You affirm that you are over the age of 13, as the Marketplace and the Vault are not
            intended for children under 13, and we will cancel the account of any person under the
            age of 13. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF MAJORITY
            WHERE YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE OF MAJORITY, THEN YOU AGREE TO
            REVIEW THESE TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR
            PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT OR
            GUARDIAN REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN
            AGREEING TO THE TERMS FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT
            FULL RESPONSIBILITY FOR THAT CHILD’S USE OF THE VAULT OR THE MARKETPLACE, INCLUDING ALL
            FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE MAY INCUR.
          </Text>
          <Text>11. INDEMNITY</Text>
          <Text>
            You shall, and hereby do, release and indemnify, defend and hold harmless OL, and its
            parents, subsidiaries, affiliates and agencies, as well as the officers, directors,
            employees, shareholders and representatives of any of the foregoing entities, from and
            against any and all losses, liabilities, expenses, damages, costs (including attorneys’
            fees and court costs) claims or actions of any kind whatsoever related to, arising out
            of, or resulting from, the Services, whether based on your use of the site or not, your
            violation of these Terms of Use, and any of your acts or omissions. OL reserves the
            right, at its own expense, to assume exclusive defense and control of any matter
            otherwise subject to indemnification by you and, in such case, you agree to cooperate
            with OL in the defense of such matter.
          </Text>
          <Text>12. DISCLAIMERS</Text>
          <Text>
            THIS SECTION 12 DOES NOT APPLY TO USERS RESIDING IN COUNTRIES OF THE EUROPEAN UNION OR
            IN THE UNITED KINGDOM.
          </Text>
          <Text>
            12.1 YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE
            SERVICES. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR
            USE OF THE SERVICES MAY NOT BE SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY
            UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR
            OWN RISK. YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES ARE PROVIDED “AS IS” AND “AS
            AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. YOU ACKNOWLEDGE
            AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER OL, ITS
            AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES NOR ITS SUPPLIERS OR LICENSORS WILL BE
            LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE,
            EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER DAMAGES
            BASED ON CONTRACT, TORT, STRICT LIABILITY, INFRINGEMENT OF INTELLECTUAL PROPERTY OR
            THEFT OR MISAPPROPRIATION OF PROPERTY OR ANY OTHER THEORY (EVEN IF OL HAD BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE SERVICES; THE USE OR THE
            INABILITY TO USE THE SERVICES; UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA; STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES; ANY
            ACTIONS OL TAKES OR FAILS TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND; HUMAN ERRORS;
            TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES;
            OMISSIONS, INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK,
            PROVIDERS, OR GAMES (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT
            PARTICIPATION IN THE GAMES); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO
            FULLY ACCESS THE SERVICES OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR
            UNAUTHORIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE
            OR INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY
            COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO THE SERVICES. SOME JURISDICTIONS DO
            NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
            LIMITATIONS MAY NOT APPLY TO YOU.
          </Text>
          <Text>
            12.2 OL HEREBY EXPRESSLY DISCLAIMS, WAIVES, RELEASES AND RENOUNCES ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON INFRINGEMENT.
          </Text>
          <Text>
            12.3 WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, OL, ITS AFFILIATES, DIRECTORS,
            OFFICERS AND EMPLOYEES , AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR
            ACCESS TO OR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE
            OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE
            DATA PROVIDED THROUGH THE SERVICES WILL BE ACCURATE OR COMPLETE, (III) THE SERVICES OR
            ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SERVICES ARE FREE OF
            BUGS, VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN
            YOU USE THE SERVICES WILL BE SECURE.
          </Text>
          <Text>
            12.4 YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE
            OVER THE INTERNET, AND AGREE THAT OL, ITS AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES
            HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR
            GROSS NEGLIGENCE.
          </Text>
          <Text>
            12.5 OL, ITS AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES WILL NOT BE RESPONSIBLE OR
            LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK
            OR FORTMATIC, METAMASK OR ANY OTHER ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY
            LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR
            INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA
            LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD
            PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR
            OTHER MEANS OF ATTACK AGAINST THE SERVICES, ETHEREUM NETWORK, OR FORTMATIC, METAMASK OR
            OTHER ELECTRONIC WALLET.
          </Text>
          <Text>
            12.6. OL IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAIN OR FORTMATIC, METAMASK OR OTHER
            ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
            REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN, INCLUDING
            FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
          </Text>
          <Text>
            12.7. OL IS NOT RESPONSIBLE FOR THE GAMES, THE CONTENT INCLUDED THEREIN AND THE NFTS
            AVAILABLE IN THE MARKETPLACE.
          </Text>
          <Text>13. LIMITATION OF LIABILITY</Text>
          <Text>
            THIS SECTION 13 DOES NOT APPLY TO USERS RESIDING IN COUNTRIES OF THE EUROPEAN UNION OR
            IN THE UNITED KINGDOM.
          </Text>
          <Text>
            13.1 YOU UNDERSTAND AND AGREE THAT OL, ITS AFFILIATES, OFFICERS, EMPLOYEES, DIRECTORS,
            AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER
            CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF
            PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS
            REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY
            OTHER INTANGIBLE LOSS, EVEN IF OL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Text>
          <Text>
            13.2 YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING
            OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO
            ACCESS OR USE) ANY PORTION OF THE SERVICES, WHETHER IN CONTRACT, TORT, STRICT LIABILITY,
            OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY
            PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR
            (B) $100.
          </Text>
          <Text>
            13.3 YOU ACKNOWLEDGE AND AGREE THAT OL HAS MADE THE SERVICES AVAILABLE TO YOU AND
            ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF
            LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
            BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. OL WOULD NOT
            BE ABLE TO PROVIDE THE USE OF THE SERVICES TO YOU WITHOUT THESE LIMITATIONS.
          </Text>
          <Text>
            13.4 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF
            LIABILITY FOR PERSONAL INJURY FROM CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT
            APPLY TO PERSONAL INJURY CLAIMS.
          </Text>
          <Text>14. PROPRIETARY RIGHTS</Text>
          <Text>
            14.1 All rights (including intellectual property rights), title, and ownership in and to
            the Services are owned exclusively by OL, its affiliates or its licensors. You
            acknowledge and agree that the Services contain proprietary and confidential information
            that is protected by applicable intellectual property and other laws. You agree not to
            reverse engineer, decompile, deploy as a service bureau, copy, modify, rent, lease,
            loan, sell, distribute, perform, display or create derivative works based on the
            Services in whole or in part. OL’s (or its affiliates’) exclusive ownership shall
            include all elements of the Services, and all intellectual property rights therein. The
            visual interfaces, graphics (including, without limitation, all art and drawings
            associated with the Services), design, systems, methods, information, computer code,
            software, “look and feel”, organization, compilation of the content, code, data, and all
            other elements of the Services (collectively, the “OL Materials’’) are owned by OL, its
            affiliates or its licensors, and are protected by copyright, trade dress, patent, and
            trademark laws, international conventions, other relevant intellectual property and
            proprietary rights, and applicable laws. All OL Materials are the copyrighted property
            of OL, its affiliates or its licensors, and all trademarks, service marks, and trade
            names contained in OL Materials are proprietary to OL, its affiliates or its licensors.
            Except as expressly set forth herein, your use of the Services does not grant you
            ownership of or any other rights with respect to any content, code, data, or other
            materials that you may access on or through the Services. OL reserves all rights in and
            to OL Materials not expressly granted to you in the Terms. For the sake of clarity, you
            understand and agree that you do not have the right to reproduce, distribute, or
            otherwise commercialize any elements of OL Materials (including, without limitation, our
            copyright in and to the art and drawings associated with the Services and content
            therein) in any way without our prior written consent in each case, which consent OL may
            withhold in its sole and absolute discretion.
          </Text>
          <Text>
            14.2 All rights (including intellectual property rights), title, and ownership in and to
            the Games are owned exclusively by the respective Developer. All third-party trademarks,
            registered trademarks, and product names mentioned in the Marketplace or contained in
            the content linked to or associated with any Games accessed through OL or NFTs displayed
            in the Marketplace are the property of their respective owners and may not be copied,
            imitated or used, in whole or in part, without the permission of the applicable
            intellectual property rights holder. Reference to any products, services, processes or
            other information by name, trademark, manufacturer, supplier or otherwise does not
            constitute or imply endorsement, sponsorship, or recommendation by OL.
          </Text>
          <Text>
            14.3 You may choose to submit comments, bug reports, ideas or other feedback about the
            Services, including without limitation about how to improve the Services (collectively,
            “Feedback”). By submitting any Feedback, you agree that OL shall own, and is free to use
            in any way in any jurisdiction, royalty free, such Feedback at our discretion and
            without additional compensation to you, and to disclose such Feedback to third parties
            (whether on a non-confidential basis, or otherwise). In the event that applicable law
            does not allow OL to own such feedback, you hereby grant us a perpetual, irrevocable,
            nonexclusive, worldwide license under all rights necessary for us to incorporate and use
            your Feedback for any purpose in any jurisdiction.
          </Text>
          <Text>
            14.4 You acknowledge and agree that you are responsible for your own conduct while
            accessing the Services, and for any consequences thereof. You agree to use the Services
            only for purposes that are legal, proper and in accordance with these Terms and any
            applicable laws or regulations. By way of example, and not as a limitation, you may not,
            and may not allow any third party to: (i) send, post, upload, transmit, distribute,
            disseminate or otherwise make available any content that infringes the intellectual
            property rights of any party and any content that contains any hate-related or violent
            content or contains any other material or products that violate or encourage conduct
            that would violate any criminal laws, any other applicable laws, or any third party
            rights; (ii) distribute viruses, worms, defects, Trojan horses, spyware, time bombs,
            cancelbots, corrupted files, hoaxes, or any other items of a destructive or deceptive
            nature, or that may harvest or collect any data or information about other users without
            their consent; (iii) impersonate another person (via the use of an email address or
            otherwise); (iv) use the Services to violate the legal rights (such as rights of privacy
            and publicity) of others; (v) engage in, promote, or encourage illegal activity
            (including, without limitation, money laundering); (vi) interfere with other users’
            enjoyment of the Services; (vii) exploit the Services for any unauthorized commercial
            purpose; (viii) post or transmit unsolicited or unauthorized advertising, or promotional
            materials, that are in the nature of “junk mail,” “spam,” “chain letters,” “pyramid
            schemes,” or any other similar form of solicitation; (ix) modify, adapt, translate, or
            reverse engineer any portion of the Services; (x) remove any copyright, trademark or
            other proprietary rights notices contained in or on the Services or any part of it; (xi)
            reformat or frame any portion of the Services; (xii) stalk, harass, or engage in any
            sexual, suggestive, lewd, lascivious, or otherwise inappropriate conduct with minors on
            the Services; (xiii) use any robot, spider, site search/retrieval application, or other
            device to retrieve or index any portion of the Services or the content posted on the
            Services, or to collect information about its users for any unauthorized purpose;; (xiv)
            create user accounts by automated means or under false or fraudulent pretenses; (xv)
            attempt to gain unauthorized access to any other user’s Marketplace Account, password or
            content; or (xvi) access or use the Services for the purpose of creating a product or
            service that is competitive with the Services.
          </Text>
          <Text>15. LINKS</Text>
          <Text>
            The Services provide, or third parties may provide, links to other World Wide Web or
            accessible sites, applications or resources (including without limitation, the
            Developers’ websites and Games). Because OL has no control over such sites, applications
            and resources, you acknowledge and agree that OL is not responsible for the activities
            or content of such external sites, applications or resources, and does not endorse and
            is not responsible or liable for any content, advertising, products or other materials
            on or available from such sites or resources. You further acknowledge and agree that OL
            shall not be responsible or liable, directly or indirectly, for any damage or loss
            caused or alleged to be caused by or in connection with use of or reliance on any such
            content, goods or services available on or through any such site or resource.
          </Text>
          <Text>16. TERMINATION AND SUSPENSION</Text>
          <Text>
            You shall have a right to terminate your Marketplace Account at any time by canceling
            and discontinuing your access to the Marketplace, the Vault, the Game and any other game
            that may be available in the future. OL may terminate or suspend all or part of the
            Services and your access to the Services immediately, without prior notice or liability.
            You will not receive any refunds if you cancel your Marketplace Account, or if these
            Terms are otherwise terminated for any reason. You agree that OL, in its sole discretion
            and for any or no reason, may terminate these Terms and suspend and/or terminate your
            Marketplace Account. You agree that any suspension or termination of your access to the
            Services may be without prior notice, and that OL will not be liable to you or to any
            third party for any such suspension or termination. If OL suspend or terminate your
            Marketplace Account due to your breach of these Terms or any suspected fraudulent,
            abusive, or illegal activity, then termination of these Terms will be in addition to any
            other remedies OL may have at law or in equity. Upon any termination or expiration your
            Marketplace Account, whether by you or us, you may no longer have access to information
            that you have posted on the Marketplace, the Vault, or the Games or that is related to
            your Marketplace Account, and you acknowledge that OL will have no obligation to
            maintain any such information in our databases or to forward any such information to you
            or to any third party. Upon termination of your Marketplace Account, your right to use
            the Marketplace, the Vault and the Games will immediately cease. The following
            provisions of these Terms survive any termination of these Terms: REPRESENTATIONS AND
            RISKS; TRANSACTION AND FEES; INDEMNITY; DISCLAIMERS; LIMITATION ON LIABILITY;
            PROPRIETARY RIGHTS; LINKS; TERMINATION AND SUSPENSION; NO THIRD-PARTY BENEFICIARIES;
            BINDING ARBITRATION AND CLASS ACTION WAIVER; GENERAL INFORMATION.
          </Text>
          <Text>17. NO THIRD PARTY BENEFICIARIES</Text>
          <Text>
            You agree that, except as otherwise expressly provided in these Terms, there shall be no
            third-party beneficiaries to the Terms.
          </Text>
          <Text>18. BINDING ARBITRATION AND CLASS ACTION WAIVER</Text>
          <Text>
            PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS,
            INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. IF YOU ARE A USER BASED IN THE EUROPEAN
            UNION OR THE UNITED KINGDOM, THE PROVISIONS OF THIS SECTION SHALL NOT APPLY AND YOU MAY
            FILE A CLAIM IN YOUR COUNTRY OF RESIDENCE.
          </Text>
          <Text>18.1 INITIAL DISPUTE RESOLUTION.</Text>
          <Text>
            The parties shall use their best efforts to engage directly to settle any dispute,
            claim, question, or disagreement and engage in good faith negotiations which shall be a
            condition to either party initiating a lawsuit or arbitration.
          </Text>
          <Text>18.2 BINDING ARBITRATION.</Text>
          <Text>
            If the parties do not reach an agreed upon solution within a period of 30 days from the
            time informal dispute resolution under the Initial Dispute Resolution provision begins,
            then either party may initiate binding arbitration as the sole means to resolve claims,
            subject to the terms set forth below.
            <br />
            Specifically, any dispute that is not resolved under the Initial Dispute Resolution
            provision shall be finally resolved by arbitration administered by JAMS under its
            Streamlined Arbitration Rules and Procedures and the JAMS Consumer Arbitration Minimum
            Standards (together, the “Rules”). The most recent version of the Rules is available at{' '}
            <Link href="www.jamsadr.com/rules-download" target="_blank" textDecor="underline">
              www.jamsadr.com/rules-download
            </Link>{' '}
            and are hereby incorporated by reference. The parties agree that the arbitration shall
            take place in Miami, Florida, unless the arbitrator determines that the dispute can be
            resolved on the submission of written papers or you exercise your right to an in-person
            hearing in your hometown area. Any decision or award rendered by the Arbitrator shall be
            final, and judgment thereon may be entered in accordance with applicable law in any
            court having jurisdiction thereof. For purposes of cost sharing, all claimants shall be
            considered one party and all respondents shall be considered one party. You and we agree
            that for any arbitration you initiate, you will pay the filing fee (up to a maximum of
            $250 if you are a consumer), and we will pay the remaining JAMS fees and costs. For any
            arbitration initiated by us, we will pay all JAMS fees and costs. The parties shall
            maintain strict confidentiality with respect to all aspects of any arbitration commenced
            pursuant to these Terms and shall not disclose the fact, conduct or outcome of the
            arbitration to any non-parties or non-participants, except to the extent required by
            applicable Law or to the extent necessary to recognize, confirm or enforce the final
            award or decision in the arbitration, without the prior written consent of all parties
            to the arbitration.
          </Text>
          <Text>18.3 CLASS ACTION WAIVER.</Text>
          <Text>
            The parties further agree that any arbitration shall be conducted in their individual
            capacities only and not as a class action or other representative action, and the
            parties expressly waive their right to file a class action or seek relief on a class
            basis. YOU AND OL AGREE THAT, TO THE EXTENT ALLOWED BY APPLICABLE LAW, EACH PARTY MAY
            BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any
            court or arbitrator determines that the class action waiver set forth in this paragraph
            is void or unenforceable for any reason or that an arbitration can proceed on a class
            basis, then the arbitration provision set forth above shall be deemed null and void in
            its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.
          </Text>
          <Text>
            18.4 EXCEPTION – LITIGATION OF INTELLECTUAL PROPERTY AND SMALL CLAIMS COURT CLAIMS
          </Text>
          <Text>
            Notwithstanding the parties’ decision to resolve all disputes through arbitration,
            either party may bring an action in state or federal court to protect its Intellectual
            Property Rights. Either party may also seek relief in a small claims court for disputes
            or claims within the scope of that court’s jurisdiction.
          </Text>
          <Text>18.5 30-DAY RIGHT TO OPT OUT.</Text>
          <Text>
            You have the right to opt-out and not be bound by the arbitration and class action
            waiver provisions set forth above by sending written notice of your decision to opt-out
            to the following email address:{' '}
            <Link href="mailto:admin@openloot.com" textDecor="underline">
              admin@openloot.com.
            </Link>{' '}
            Your notice must be sent within 30 days of your first use of the Game, otherwise you
            shall be bound to arbitrate disputes in accordance with the terms of those paragraphs.
            If you opt-out of these arbitration provisions, OL also will not be bound by them.
          </Text>
          <Text>18.6 CHANGES TO THIS SECTION</Text>
          <Text>
            OL will provide 60-days’ notice of any changes to this section. Changes will become
            effective on the 60th day, and will apply prospectively only to any claims arising after
            the 60th day.
            <br />
            These Terms and the relationship between you and OL shall be governed by the laws of the
            State of Delaware, without regard to conflict of law provisions.
            <br />
            For any dispute not subject to arbitration you and OL agree to submit to the exclusive
            jurisdiction of the State of Delaware. You further agree to accept service of process by
            mail, and hereby waive any and all jurisdictional and venue defenses otherwise
            available.
          </Text>
          <Text>19. GENERAL INFORMATION</Text>
          <Text>
            19.1 ENTIRE AGREEMENT. These Terms (and any additional terms, rules and conditions of
            participation that OL may post on the Services) constitute the entire agreement between
            you and OL with respect to the Services and supersede any prior agreements, oral or
            written, between you and OL. In the event of a conflict between these Terms and the
            additional terms, rules and conditions of participation, the latter will prevail over
            the Terms to the extent of the conflict.
          </Text>
          <Text>
            19.2 WAIVER AND SEVERABILITY OF TERMS. The failure of OL to exercise or enforce any
            right or provision of the Terms shall not constitute a waiver of such right or
            provision. If any provision of the Terms is found by an arbitrator or court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court
            should endeavor to give effect to the parties’ intentions as reflected in the provision,
            and the other provisions of the Terms remain in full force and effect.
          </Text>
          <Text>
            19.3 STATUTE OF LIMITATIONS. You agree that regardless of any statute or law to the
            contrary, any claim or cause of action arising out of or related to the use of the
            Services or the Terms must be filed within one (1) year after such claim or cause of
            action arose or be forever barred.
          </Text>
          <Text>
            19.4 SECTION TITLES. The section titles in the Terms are for convenience only and have
            no legal or contractual effect.
          </Text>
          <Text>
            19.5 COMMUNICATIONS. Users with questions, complaints or claims with respect to the
            Services may contact us using the relevant contact information set forth above and at
            <br />
            <Link href="mailto:admin@openloot.com" textDecor="underline">
              admin@openloot.com.
            </Link>
            <br />
            [1309 Coffeen Ave STE 1200, Sheridan, WY 82801]
          </Text>
          <Text>Error Resolution Forms</Text>
          <Text>
            (a) Initial and annual error resolution notice <br />
            In Case of Errors or Questions About Your Subscriptions Telephone us at [insert
            telephone number] Write us at [insert address] or email us at [insert email address]] as
            soon as you can, if you think your statement or receipt is wrong or if you need more
            information about a transfer listed on the statement or receipt. We must hear from you
            no later than 60 days after we sent the FIRST statement on which the problem or error
            appeared. <br />
            (1) Tell us your name and username. <br />
            (2) Describe the error or the transfer you are unsure about, and explain as clearly as
            you can why you believe it is an error or why you need more information. <br />
            (3) Tell us the amount of the suspected error. <br />
            If you tell us orally, we may require that you send us your complaint or question in
            writing within 10 business days. <br />
            We will determine whether an error occurred within 10 business days after we hear from
            you and will correct any error promptly. If we need more time, however, we may take up
            to 45 days to investigate your complaint or question. If we decide to do this, we will
            credit your account within 10 business days for the amount you think is in error, so
            that you will have the use of the money during the time it takes us to complete our
            investigation. If we ask you to put your complaint or question in writing and we do not
            receive it within 10 business days, we may not credit your account. <br />
            For errors involving new accounts or foreign-initiated transactions, we may take up to
            90 days to investigate your complaint or question. For new accounts, we may take up to
            20 business days to credit your account for the amount you think is in error. <br />
            We will tell you the results within three business days after completing our
            investigation. If we decide that there was no error, we will send you a written
            explanation. You may ask for copies of the documents that we used in our investigation.{' '}
          </Text>
          <Text>
            (b) Error resolution notice on periodic statements. <br />
            In Case of Errors or Questions About Your Subscriptions Telephone us at [insert
            telephone number] or Write us at [insert address] as soon as you can, if you think your
            statement or receipt is wrong or if you need more information about a transfer on the
            statement or receipt. We must hear from you no later than 60 days after we sent you the
            FIRST statement on which the error or problem appeared. <br />
            (1) Tell us your name and account number (if any). <br />
            (2) Describe the error or the transfer you are unsure about, and explain as clearly as
            you can why you believe it is an error or why you need more information. <br />
            (3) Tell us the amount of the suspected error. <br />
            We will investigate your complaint and will correct any error promptly. If we take more
            than 10 business days to do this, we will credit your account for the amount you think
            is in error, so that you will have the use of the money during the time it takes us to
            complete our investigation.
          </Text>
        </Stack>
      </Box>

      <Text
        as="i"
        size="sm"
        bg="black"
        display="block"
        pt={5}
        borderTop="1px"
        borderColor="seprator"
      >
        <Text as="sup" fontSize="10px">
          1
        </Text>{' '}
        U.S. Patent No. 10,946,283 B1
      </Text>

      <Flex
        mt={5}
        direction="column"
        width="100%"
        height="100%"
        justifyContent="flex-end"
        borderTop="1px"
        borderColor="seprator"
      >
        <Link
          href="/"
          alignSelf="flex-end"
          display="inline-flex"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Button mt="6" variant="light-outline" cursor="pointer">
            Back
          </Button>
        </Link>
      </Flex>
    </Container>
  </>
)
