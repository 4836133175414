import { Box, Flex, Heading, HStack, SimpleGrid, Text } from '@chakra-ui/react'
import { Button, CardSkeleton, Select } from 'components/atoms'
import { ItemCard } from 'components/organisms'
import { useSecondaryOptions } from 'hooks/useSecondaryOptions'
import { useRouter } from 'next/router'
import { ChangeEvent, Fragment, useMemo } from 'react'
import { Game } from 'types/vault/v2/Game'
import { getRandomNumber, handleRouterUrl } from 'utils/globals'
import { validate as uuidValidate } from 'uuid'
import { SORT_ORDER } from '../../constants'
import { useFeature } from '../../hooks/useFeature'

interface CategoriesProps {
  game?: Game
}

export const Categories: React.FC<CategoriesProps> = ({ game }) => {
  const router = useRouter()
  const { query } = router

  const tags = useMemo(() => query?.category, [query.category])

  const nftTags = useMemo(() => query?.tag, [query.tag])

  const rarity = useMemo(() => query?.rarity, [query.rarity])

  const sort = useMemo(() => {
    const sort = query?.sort
    const valid = SORT_ORDER.find(({ id }) => id === sort)
    return valid ? sort : SORT_ORDER[0].id
  }, [query.sort])

  const gameId = useMemo(() => {
    const gameIds = game?.id ? [game?.id] : String(query?.gameId).split(',')
    const validGameIds: string[] = []

    gameIds.forEach((id) => {
      if (uuidValidate(id)) validGameIds.push(id)
    })

    const gameId = validGameIds.join(',')

    return gameId || undefined
  }, [query.gameId])

  const priceTo = useMemo(() => {
    const priceTo = query?.priceTo
    return priceTo ? +priceTo : undefined
  }, [query.priceTo])

  const priceFrom = useMemo(() => {
    const priceFrom = query?.priceFrom
    return priceFrom ? +priceFrom : undefined
  }, [query.priceFrom])

  const mintedTo = useMemo(() => {
    const mintedTo = query?.mintedTo
    return mintedTo ? +mintedTo : undefined
  }, [query.mintedTo])

  const mintedFrom = useMemo(() => {
    const mintedFrom = query?.mintedFrom
    return mintedFrom ? +mintedFrom : undefined
  }, [query.mintedFrom])

  const hasOnSaleFilter = useFeature('marketplaceOnSaleFilter')
  const onSale = useMemo(() => {
    if (!hasOnSaleFilter) {
      return true
    }
    const onSale = query?.onSale === undefined ? true : query?.onSale === 'true'
    const nonOnSale = query?.nonOnSale === 'true'
    return (onSale && nonOnSale) || (!onSale && !nonOnSale) ? undefined : Boolean(onSale)
  }, [query.onSale, query.nonOnSale])

  const searchStr = useMemo(() => query?.search, [query.search])

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useSecondaryOptions({
    tags: tags as undefined | string,
    nftTags: nftTags as undefined | string,
    order: sort as undefined | string,
    sort: sort as string,
    rarity: rarity as undefined | string,
    priceFrom,
    priceTo,
    mintedFrom,
    mintedTo,
    q: searchStr as undefined | string,
    onSale,
    ...(gameId ? { gameId } : {}),
  })

  const handleSort = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    handleRouterUrl({ router, queryObj: { sort: value } })
  }

  return (
    <Box as="section" mt={[6, null, 8, 10, '44px']}>
      <Flex flexDir={{ base: 'column', lg: 'row' }} justifyContent="space-between">
        <HStack justifyContent="space-between" mt={{ base: '17px', lg: 0 }} flex={1}>
          <Text size="sm" color="whiteAlpha.600">
            {data?.pages[0].totalItems || 0} items
          </Text>

          <Select variant="filter" onChange={handleSort}>
            {SORT_ORDER.map(({ label, id }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
          </Select>
        </HStack>
      </Flex>

      {data?.pages[0].items.length === 0 && (
        <Heading as="h4" mt={10} size="lg" color="whiteAlpha.800">
          No items are found
        </Heading>
      )}

      <SimpleGrid
        mt={['17px', null, 3.5]}
        columns={[2, null, 3, null, 4, 5]}
        gap={{ base: 3, md: 5, '2xl': 7, '3xl': '35px' }}
      >
        {data?.pages.map((page, index) => (
          <Fragment key={`page-${getRandomNumber(1000)}-${index}`}>
            {page.items.map((listingItemMetadata, index) => (
              <ItemCard key={index} listingItemMetadata={listingItemMetadata} />
            ))}
          </Fragment>
        ))}

        {(isLoading || (hasNextPage && isFetchingNextPage)) && <CardSkeleton numCards={10} />}
      </SimpleGrid>

      {!isLoading && hasNextPage && (
        <Button
          mt={3.5}
          display="flex"
          mx="auto"
          variant="light-outline"
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
        >
          Show More
        </Button>
      )}
    </Box>
  )
}
