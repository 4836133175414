import NextLink from 'next/link'
import { Fragment, ReactNode } from 'react'
import { Button, ButtonProps } from 'components/atoms'

interface ButtonLinkProps extends ButtonProps {
  href: string
  disabled?: boolean
  children?: ReactNode
  target?: string
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  href,
  target,
  disabled,
  children,
  ...props
}) => {
  const LinkWrapper = disabled ? Fragment : NextLink

  return (
    <LinkWrapper href={href} passHref target={target}>
      <Button disabled={disabled} {...props}>
        {children}
      </Button>
    </LinkWrapper>
  )
}
