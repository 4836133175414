import AuthApi from 'lib/api/AuthApi'
import { useMutation } from 'react-query'

const fetchVerificationCodeSend = (): Promise<Response> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/v2/auth/2fa/email/generate',
  })

export const useEmailVerificationStartMutation = () => useMutation(fetchVerificationCodeSend)
