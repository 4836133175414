import * as queryString from 'query-string'
import { setCookie } from 'react-use-cookie'
import { getDeviceId } from './GetDeviceId'
import { getSessionId } from './GetSessionId'
import { getUserAgent } from './GetUserAgent'
import { ServiceError } from './ServiceError'

const CLIENT_ID = 'marketplace'

const ERRORS = {
  500: 'Internal Server Error',
  401: 'Unauthorized',
  403: 'Forbidden',
}

export interface RequestParams {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  url: string
  params?: Record<string, unknown>
  body?: Record<string, unknown> | FormData
  headers?: Record<string, unknown>
}

export default class ApiBase {
  /**
   * Method that will parse to json the body if possible
   * @param response
   * @returns {Promise.<TResult>}
   */
  static parseBody(response: any) {
    return Promise.resolve(response.json())
      .then((json) => json)
      .catch(() => Promise.resolve())
  }

  static doRequest(baseURL: string, { method, url, params, body, headers }: RequestParams) {
    const { isMobile, userAgent } = getUserAgent()

    const isFormData = body instanceof FormData

    const commonHeaders = {
      ...(body && !isFormData && { 'Content-Type': 'application/json' }),
      'x-client-id': CLIENT_ID,
      'x-session-id': typeof window === 'undefined' ? '' : getSessionId(),
      'x-device-id': typeof window === 'undefined' ? '' : getDeviceId(),
      'x-user-agent': userAgent,
      'x-is-mobile': isMobile,
    }

    const finalHeaders: any = {
      ...commonHeaders,
      ...headers,
    }

    const options: RequestInit = {
      method,
      headers: new Headers(finalHeaders),
      credentials: 'include',
      ...(body && { body: isFormData ? body : JSON.stringify(body) }),
      mode: 'cors',
    }

    let fullUrl = `${baseURL}${url}`

    if (params) {
      const queryStrParams = queryString.stringify(params, { arrayFormat: 'index' })
      fullUrl = fullUrl.concat(`?${queryStrParams}`)
    }

    const request = new Request(fullUrl, options)

    return fetch(request)
      .then((response) => {
        switch (response.status) {
          case 500: {
            const serviceError = new ServiceError(500, ERRORS[500], method, url, fullUrl)

            return Promise.reject(serviceError)
          }

          case 401: {
            // Handle possible expired token scenario here
            const serviceError = new ServiceError(401, ERRORS[401], method, url, fullUrl)
            return this.parseBody(response).then((json) => {
              serviceError.setPayload(json)

              return Promise.reject(serviceError)
            })
          }

          case 403: {
            // Handle possible suspended user
            const serviceError = new ServiceError(403, ERRORS[403], method, url, fullUrl)
            return this.parseBody(response).then((json) => {
              setCookie('userStatus', json.status)
              setCookie('userStatusReason', json.reason)
              serviceError.setPayload(json)

              return Promise.reject(serviceError)
            })
          }
          default: {
            break
          }
        }

        // Status is not the range 200 to 299
        if (!response.ok) {
          const serviceError = new ServiceError(
            response.status,
            response.statusText,
            method,
            url,
            fullUrl
          )

          return this.parseBody(response).then((json) => {
            serviceError.setPayload(json)
            return Promise.reject(serviceError)
          })
        }

        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
