import VaultApi from 'lib/api/VaultApi'
import { useQueries, useQuery } from 'react-query'
import { features } from 'types/vault/Config'

const fetchFeatureAccessibility = (featureName: features): Promise<boolean> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/features/${featureName}/whitelistUser`,
  }).then((res) => res.json())

export const useFeatureAccessibility = (featureName: features) =>
  useQuery([`feature-whitelist`, { featureName }], () => fetchFeatureAccessibility(featureName))

export const useFeaturesAccessibility = (featureNames: features[]) =>
  useQueries(
    featureNames.map((featureName) => ({
      queryKey: [`feature-whitelist`, { featureName }],
      queryFn: () => fetchFeatureAccessibility(featureName),
    }))
  )
