import { useEffect, useState } from 'react'

type COUNTDOWN_KEYS = 'days' | 'hours' | 'minutes' | 'seconds'

export type CountdownResult = Record<COUNTDOWN_KEYS, number>

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

const computeCountdown = (delta: number) => {
  const positiveDelta = Math.max(delta, 0)

  return {
    days: Math.floor(positiveDelta / DAY),
    hours: Math.floor((positiveDelta % DAY) / HOUR),
    minutes: Math.floor((positiveDelta % HOUR) / MINUTE),
    seconds: Math.floor((positiveDelta % MINUTE) / SECOND),
  } as CountdownResult
}

const getDelta = (deadline: number, computeIfExpired: boolean) => {
  const now = Date.now()
  return deadline < now || !computeIfExpired ? deadline - now : now - deadline
}

export const useCountdown = (deadline: number, showTimePassedAfterExpire = false) => {
  const [countdown, setCountdown] = useState(
    computeCountdown(getDelta(deadline, showTimePassedAfterExpire))
  )

  useEffect(() => {
    let intervalHandler: ReturnType<typeof setInterval>

    if (Date.now() < deadline) {
      intervalHandler = setInterval(() => {
        const now = Date.now()
        setCountdown(computeCountdown(deadline - now))
        if (now >= deadline && !showTimePassedAfterExpire) {
          clearTimeout(intervalHandler)
        }
      }, 1000)
    } else if (showTimePassedAfterExpire) {
      intervalHandler = setInterval(() => {
        const now = Date.now()
        setCountdown(computeCountdown(now - deadline))
      }, 1000)
    }

    return () => clearTimeout(intervalHandler)
  }, [deadline])

  return countdown
}
