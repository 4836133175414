const colors = {
  alert: '#FEEBCBE6',
  blackAlpha: {
    200: '#00000033',
    300: '#0000004D',
    400: '#00000066',
    600: '#00000099',
    800: '#000000CC',
  },
  blue: { 400: '#A0AED4', link: '#2F80ED' },
  darkGrey: '#2A2A2A',
  gray: {
    default: '#DADADA',
    200: '#E2E8F0',
    250: '#EBEBEB',
    300: '#CDCDCD',
    400: '#828282',
    500: '#8C8C8C',
    550: '#4c4c4c',
    600: '#393939',
    800: '#272727',
    900: '#191919',
  },
  bright: {
    default: '#F2F2F2',
    100: '#F2F2F21A',
    200: '#F2F2F233',
  },
  rentals: '#534233',
  'rentals-dark': '#3E2B1A',
  'menu-content': '#252020',
  'table-stroke': '#E0E0E033',
  'modal-stroke': '#D4E5F5',
  modalStrokeAlpha: {
    200: '#D4E5F533',
    500: '#D4E5F580',
  },
  'input-placeholder': '#A4A4A4',
  issue: '#BABABA',
  input: '#718096',
  'subscribe-btn': '#202733',
  'title-field': '#999999',
  container: '#292929',
  divider: '#D4E5F533',
  'divider-light': '#D4E5F580',
  fees: '#ECC94B',
  error: '#E53E3E',
  'go-button': '#121A2A',
  grey: '#1A1A1A',
  highlight: {
    default: '#ED8936',
    200: '#ED893633',
    700: '#ED893699',
    dark: '#BD671F',
  },
  gasFee: '#FFD32A',
  hover: '#F6AD55',
  lightDark: '#151515',
  lightGrey: '#343434',
  nav: '#EEEEEEB3',
  navHover: '#EEEEEE',
  rarity: {
    common: '#A4B0BE',
    uncommon: '#1CBF6A',
    rare: '#159CFD',
    epic: '#A369FF',
    legendary: '#E67E22',
    mythic: '#FFD32A',
    exalted: '#EF5777',
    exotic: '#BE2EDD',
    transcendent: '#FF3838',
    unique: '#F368E0',

    hover: {
      common: '#A4B0BE33',
      uncommon: '#1CBF6A33',
      rare: '#159CFD33',
      epic: '#A369FF33',
      legendary: '#E67E2233',
      mythic: '#FFD32A33',
      exalted: '#EF577733',
      exotic: '#BE2EDD33',
      transcendent: '#FF383833',
      unique: '#F368E033',
    },
  },
  'red-border': '#EE7E84',
  'red-warning': '#F56565',
  red: {
    500: '#E53E3E',
  },
  seprator: '#393939',
  state: {
    inactive: '#8C8C8C',
    pending: '#81B3ED',
    warning: '#ED8936',
    success: '#54BC8C',
    error: '#F56565',
  },
  'text-cards': '#2D3748',
  'text-card-text': '#D4E5F5B2',
  'toast-deny': '#F9C1C1',
  white: '#FFFFFF',
  'bg-footer': '#020202E6',
  whiteAlpha: {
    50: '#FFFFFF0D',
    100: '#FFFFFF1A',
    150: '#FFFFFF26',
    200: '#FFFFFF33',
    300: '#FFFFFF4D',
    400: '#FFFFFF66',
    500: '#FFFFFF80',
    600: '#FFFFFF99',
    700: '#FFFFFFB3',
    900: '#FFFFFFE6',
  },
  yellow: {
    default: '#ECC94B',
    300: '#ECC94B4D',
    warning: '#D2B650',
  },
  table: {
    header: '#393939',
    row: '#191919',
    headerIcon: '#8C8C8C',
  },
}

export default colors
