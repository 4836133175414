import { Image, HStack, UnorderedList, Link, ListItem, Text, Box } from '@chakra-ui/react'
import { Button } from 'components/atoms'
import { UserMenuInfo } from 'components/organisms/UserMenuInfo'
import { useLogoutMutation } from 'hooks/useLogoutMutation'
import { User } from 'types/vault/User'

interface MobileUserMenuProps {
  onClose: () => void
  user: User
  navItems: {
    name: string
    url: string
  }[]
}

export const MobileUserMenu: React.FC<MobileUserMenuProps> = ({ onClose, user, navItems }) => {
  const { mutate } = useLogoutMutation()

  const handleLogout = () => {
    mutate()
    onClose()
  }

  return (
    <Box px={5} pt="12px" bg="white" pos="relative" color="menu-content">
      <Button size="auto" variant="none" pos="absolute" top="12px" right="17px" onClick={onClose}>
        <Image src="/images/icons/close.svg" alt="close icon" />
      </Button>

      <Box px="30px">
        <UserMenuInfo user={user} onCloseMenu={onClose} />
      </Box>

      <UnorderedList listStyleType="none" m={0} mt={3}>
        {navItems.map(({ name, url }) => (
          <Link
            href={url}
            _focus={{ boxShadow: 'none' }}
            _hover={{ fontWeight: 'bold' }}
            transition="all 0.2s"
            key={name}
            passHref
          >
            <ListItem py={3} borderTop="1px" borderColor="bright.default" onClick={onClose}>
              <Text as="a">{name}</Text>
            </ListItem>
          </Link>
        ))}
      </UnorderedList>

      <HStack justifyContent="space-between" py={3} borderTop="1px" borderColor="bright.default">
        <Button
          variant="none"
          size="auto"
          fontSize="xs"
          color="gray.500"
          onClick={handleLogout}
          _hover={{ color: 'gray.700' }}
        >
          Log Out
        </Button>
        <Button variant="none" size="auto" fontSize="xs" color="gray.500" onClick={handleLogout}>
          <Image src="/images/icons/logout.svg" alt="logout icon" />
        </Button>
      </HStack>
    </Box>
  )
}
