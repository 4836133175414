import { formatDateInUTC, formatMoney, formatNumber } from 'lib/format'
import { Attribute } from 'types/vault/v2/Attribute'

export const formatVisualListItemPrice = (price: number | undefined, isOnSale: boolean) =>
  isOnSale && price ? formatMoney(price) : '$ --'

export const formatAttributeValue = (attribute: Attribute) => {
  switch (attribute.type) {
    case 'string':
      return attribute.value
    case 'number':
      return formatNumber(Number(attribute.value))
    case 'date':
      return formatDateInUTC(new Date(attribute.value))
    default:
      return attribute.value
  }
}
