import { Flex, VStack, Text, Heading, Box } from '@chakra-ui/react'
import { ButtonLink, RequireFeatureAccessible, Span } from 'components/atoms'
import { VipBadge } from 'components/atoms/VipBadge'
import { useAnyFeature } from 'hooks/useFeature'
import { formatMoney } from 'lib/format'
import Link from 'next/link'
import { User } from 'types/vault/User'

interface UserInfoMenuProps {
  user: User
  onCloseMenu?: () => void
}

export const UserMenuInfo: React.FC<UserInfoMenuProps> = ({ user, onCloseMenu }) => {
  const { vipTier, balance, username } = user
  const hasDeposit = useAnyFeature(['depositCrypto', 'depositWire'])

  return (
    <VStack mx="auto" maxW={{ base: '350px', sm: '400px' }}>
      <Link href="/account/profile" passHref>
        <Heading
          as="h4"
          fontWeight={300}
          size="base"
          py={1}
          bgColor="gray.250"
          w="full"
          textAlign="center"
          cursor="pointer"
        >
          <Span cursor="pointer"> {username}</Span>
        </Heading>
      </Link>
      <Flex mt={3} justifyContent="space-between" w="full" alignItems="stretch">
        <VStack spacing={1.5} flex="1">
          <Heading as="h5" size="base">
            {formatMoney(balance)}
          </Heading>
          {hasDeposit && (
            <ButtonLink
              borderRadius="full"
              href="/wallet/deposits"
              variant="dark"
              color="white"
              size="none"
              px="22px"
              h="29px"
              fontSize="12px"
              _hover={{
                color: 'whiteAlpha.700',
              }}
              bgColor="text-cards"
              onClick={onCloseMenu}
            >
              Add Funds
            </ButtonLink>
          )}
        </VStack>

        <RequireFeatureAccessible feature="olToken" redirectFourOhFour={false}>
          <>
            <Box mx={2} w="px" bgColor="bright.default" />

            <VStack spacing={1.5} justifyContent="space-between">
              <Text
                fontFamily="heading"
                size="sm"
                color="menu-content"
                lineHeight="1.25rem"
                fontWeight={500}
              >
                VIP Tier
              </Text>

              <VipBadge vipTier={vipTier} />
            </VStack>
          </>
        </RequireFeatureAccessible>
      </Flex>
    </VStack>
  )
}
