import { Text } from '@chakra-ui/react'
import { ItemRarity } from 'types/vault/ItemRarity'
import { UsersVIPTierResponse, VIPTier } from 'types/vault/User'
import { OL_TOKEN_VIP_TIER_ARTICLE_URL } from '../../constants'
import { RarityBadge, Span } from '.'

interface VipBadgeProps {
  vipTier?: UsersVIPTierResponse
}

function getRarityFromVipTier(vipTierId: VIPTier): ItemRarity | null {
  const mapToItemRarity: Record<VIPTier, ItemRarity | null> = {
    [VIPTier.NON_VIP]: null,
    [VIPTier.COMMON]: 'common',
    [VIPTier.UNCOMMON]: 'uncommon',
    [VIPTier.RARE]: 'rare',
    [VIPTier.EPIC]: 'epic',
    [VIPTier.LEGENDARY]: 'legendary',
    [VIPTier.MYTHIC]: 'mythic',
    [VIPTier.EXALTED]: 'exalted',
    [VIPTier.EXOTIC]: 'exotic',
    [VIPTier.TRANSCENDENT]: 'transcendent',
    [VIPTier.UNIQUE]: 'unique',
  }
  return mapToItemRarity[vipTierId]
}

export const VipBadge: React.FC<VipBadgeProps> = ({ vipTier }) => {
  if (vipTier === undefined) return null

  const itemRarity = getRarityFromVipTier(vipTier.tierId)

  return (
    <Span className="cursor-pointer">
      <a href={OL_TOKEN_VIP_TIER_ARTICLE_URL} target="_blank" rel="noreferrer">
        {vipTier.tierId === VIPTier.NON_VIP ? (
          <Text px={6} border="1px solid" borderRadius="full" fontSize="sm">
            No VIP
          </Text>
        ) : (
          itemRarity && <RarityBadge rarity={itemRarity} title={vipTier.tierName} />
        )}
      </a>
    </Span>
  )
}
