import { ItemRarity } from './types/vault/ItemRarity'

export const MIN_USER_AGE = 13
export const MIN_AGE_FOR_KYC = 18

export const LISTING_API_URL = (process.env.NEXT_PUBLIC_LISTING_API_URL ||
  process.env.NEXT_PUBLIC_API_URL) as string
export const VAULT_API_URL = (process.env.NEXT_PUBLIC_VAULT_API_URL ||
  process.env.NEXT_PUBLIC_API_URL) as string
export const AUTH_API_URL = process.env.NEXT_PUBLIC_TG_AUTH_URL as string

export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID as string

export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string

export const ZENDESK_BOT_KEY = process.env.NEXT_PUBLIC_ZENDESK_BOT_KEY

export const ENABLE_AMPLITUDE_TRACKING =
  (process.env.NEXT_PUBLIC_ENABLE_AMPLITUDE_TRACKING || '').toLowerCase() === 'true'

export const TAG_MANAGER_ID = process.env.NEXT_PUBLIC_TAG_MANAGER_ID as string

export const HOTJAR_ID = Number(process.env.NEXT_PUBLIC_HOTJAR_ID)

export const NEXT_SIFT_BEACON_KEY = process.env.NEXT_SIFT_BEACON_KEY as string
export const CHECKOUT_PUBLIC_KEY = process.env.NEXT_PUBLIC_CHECKOUT_PUBLIC_KEY as string
export const MOONPAY_PUBLISHEABLE_KEY = process.env.NEXT_PUBLIC_MOONPAY_PUBLISHEABLE_KEY as string
export const RAMP_API_KEY = process.env.NEXT_PUBLIC_RAMP_API_KEY as string
export const RAMP_HOST = process.env.NEXT_PUBLIC_RAMP_HOST as string
export const COINBASE_APP_ID = process.env.NEXT_PUBLIC_COINBASE_APP_ID as string

export const MOONPAY_ENVIRONMENT = process.env.NEXT_PUBLIC_MOONPAY_ENVIRONMENT as
  | 'production'
  | 'sandbox'

export const DEFAULT_PAGE_SIZE = 50
export const RENTAL_DEFAULT_PAGE_SIZE = 20

export const MAX_AMOUNT_OF_CREDIT_CARDS = 5

export const MAX_AMOUNT_OF_WIRE_ACCOUNTS = 5

export const MAX_SAFE_NUMBER = 9999999999.9999999999

export const isProduction =
  process.env.VERCEL_ENV === 'production' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export const DEPOSIT_WITH_CREDIT_CARD_MIN_PRICE = isProduction ? 10 : 2
export const DEPOSIT_WITH_CREDIT_CARD_MAX_PRICE = 10000

export const VERIFICATION_SUCCESS_URL =
  process.env.NEXT_PUBLIC_VERIFICATION_SUCCESS_URL ??
  `${typeof window !== 'undefined' ? window.location.origin : ''}/account/profile`

export const FAQ_LINK = 'https://openloot.zendesk.com/hc/en-us'

export const AMBASSADOR_FAQ_LINK =
  'https://docs.google.com/document/d/1aoQ7KTW0TtmHpNsKDILxv6CaCA5FjIgXGtw19M5TjTU'
export const AMBASSADOR_TERMS_OF_USE_URL = '/ambassador/terms-of-use'

export const AUCTIONS_FAQ_LINk = 'https://medium.com/playbigtime'

export const SITE_URL = 'https://openloot.com/'

export const NEWSLETTER_URL = 'https://bigtime720.activehosted.com/proc.php'

export const OPENSEA_LINK = 'https://opensea.io/collection/bigtime-founders'

export const BIGTIME_DOWNLOAD_LINK = 'https://play.bigtime.gg'

export const OPENLOOT_CDN_URL = 'https://openloot-metadata.bigtime.gg'

export const OPENLOOT_MEDIUM_URL = 'https://medium.com/@Openloot'

export const OPENLOOT_WIKI_URL = 'https://wiki.openloot.com'

export const TWITTER_DISABLED_ARTICLE_URL =
  'https://openloot.zendesk.com/hc/en-us/articles/15375472341396-Twitter-Login-Temporarily-Disabled'

export const UNDER_18_HELP_ARTICLE_URL =
  'https://openloot.zendesk.com/hc/en-us/articles/19430557261460-Underage-Users'

export const OL_TOKEN_VIP_TIER_ARTICLE_URL = 'https://wiki.openloot.com/ol-token/vip-tier-details'

export const MAX_DEPOSIT_WITHOUT_ID_VERIFICATION = '2,000'

export const LOOT_BOX_SALES_SLUG = 'loot-boxes-sales'
export const BIG_TIME_GAME_ID = '56a149cf-f146-487a-8a1c-58dc9ff3a15c'

export const SIGNATURE_ID_SESSION_KEY = 'signedSignatureId'

export const REDIRECT_LINK_ORDER_PLACEHOLDER = `$ORDERID`
export const QUERY_CLIENT_CONFIG = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2,
      cacheTime: 1000 * 60 * 5,
    },
  },
}

export const RARITY_FILTER_ITEMS: { id: ItemRarity; title: string }[] = [
  { id: 'common', title: 'Common' },
  { id: 'uncommon', title: 'Uncommon' },
  { id: 'rare', title: 'Rare' },
  { id: 'epic', title: 'Epic' },
  { id: 'legendary', title: 'Legendary' },
  { id: 'mythic', title: 'Mythic' },
  { id: 'exalted', title: 'Exalted' },
  { id: 'exotic', title: 'Exotic' },
  { id: 'transcendent', title: 'Transcendent' },
  { id: 'unique', title: 'Unique' },
]

export const SORT_ORDER = [
  { label: 'Sort A-Z', id: 'name:asc' },
  { label: 'Sort Z-A', id: 'name:desc' },
  { label: 'Price - low to high', id: 'price:asc' },
  { label: 'Price - high to low', id: 'price:desc' },
]

export const CREATE_RENTAL_SORT_ORDER = [
  { label: 'Sort A-Z', id: 'name:asc' },
  { label: 'Sort Z-A', id: 'name:desc' },
]

export const CREATE_RENTAL_ISSUE_SORT_ORDER = [
  { label: 'Issue # - low to high', id: 'issued_id:asc' },
  { label: 'Issue # - high to low', id: 'issued_id:desc' },
]

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

export const RENTALS_MIN_PRICE = 10
export const RENTALS_MAX_PRICE = 999999
export const RENTALS_MIN_DISCOUNT_PERCENT = 10
export const RENTALS_MAX_DISCOUNT_PERCENT = 90
export const DEFAULT_MARKETPLACE_ROUTE = `/marketplace?gameId=${BIG_TIME_GAME_ID}`

export enum LoginProviders {
  GOOGLE = 'Google',
  APPLE = 'Apple',
  TWITCH = 'Twitch',
  DISCORD = 'Discord',
  TWITTER = 'Twitter',
}
export const DEFAULT_PROVIDERS = [
  LoginProviders.GOOGLE,
  LoginProviders.APPLE,
  LoginProviders.TWITCH,
  LoginProviders.DISCORD,
  LoginProviders.TWITTER,
]

// List of game id and game slugs of games that we want to hide from marketplace but we want to show into my collections can see the items and withdraw them
// This should be temporal and we move this to a configurable field in the backend
export const GAMES_HIDDEN = ['8c6bfff6-98fa-435d-bd18-c58a8ef02abe', 'reign-of-terror']

export const MYSTERY_BOX_TAG = 'mysterybox'

export const RentalPeriod = isProduction
  ? ({
      SEVEN_DAYS: 604800, // 7 days
      THIRTY_DAYS: 2592000, // 30 days
    } as const)
  : ({
      SEVEN_DAYS: 1800, // 30 minutes
      THIRTY_DAYS: 7200, // 2 hours
    } as const)

export const TIME_CRYSTAL_ID = 'a98b86d8-ed8f-4e50-a223-e3a19191445b'
export const BIGTIME_TOKEN_ID = 'a07b874f-d30a-41d5-bfb1-879abf474590'

export const MAX_AMBASSADOR_PROFILE_SIZE = 5 * 1024 * 1024 // 5mb in bytes
