import { Box, Divider, Flex, Skeleton } from '@chakra-ui/react'
import { Video } from 'components/atoms'
import { getItemVideoUrl } from 'lib/image'
import NextLink from 'next/link'
import { isSaleSoldOut } from 'utils/sale'
import { formatMoney, formatNumber } from '../../../lib/format'
import { ItemMetadata } from '../../../types/vault/ItemMetadata'
import { ItemRarity } from '../../../types/vault/ItemRarity'
import { BoxSale, ConfigBox } from '../../../types/vault/v2/Sale'
import { Card } from '../../atoms/Card/Card'
import { SaleItemCardTag } from '../../atoms/Card/CardTag'
import { RarityBadge } from '../../atoms/RarityBadge'
import { AvailableAtTag } from './AvailableAtTag'

interface SaleItemCardProps {
  sale: BoxSale
  itemMetadata?: ItemMetadata
  slug?: string
}

export const SaleItemCardFullImg = ({ sale, itemMetadata, slug }: SaleItemCardProps) => {
  const isLoaded = Boolean(sale && slug)
  const now = Date.now()
  const config = sale?.config as ConfigBox
  const isSoldOut = isSaleSoldOut(sale)
  const badgeName = itemMetadata?.rarity ?? 'common'
  const badgeTitle = badgeName[0].toUpperCase() + badgeName.slice(1).toLowerCase()
  const isEnabled = sale.status === 'ENABLED'
  const availableAt = new Date(config?.availableAt).getTime() || 0

  const cardContent = (
    <Card.Root as="a" pointerEvents={isLoaded ? 'auto' : 'none'} h="430px" pos="relative">
      <Box pos="absolute" w="100%" h="100%">
        <Video
          w="100%"
          h="100%"
          objectFit="cover"
          objectPosition="center"
          src={
            config?.imageUrl ??
            getItemVideoUrl(
              itemMetadata?.collection as string,
              itemMetadata?.optionName as string,
              itemMetadata?.videoUrl
            )
          }
          poster={config?.imageUrl ?? itemMetadata?.imageUrl}
        />
        <Box
          inset={0}
          pos="absolute"
          background="linear-gradient(360deg, rgba(0, 0, 0, 0.8) 1.16%, rgba(0, 0, 0, 0) 48.14%)"
          zIndex={10}
        />
      </Box>

      {isSoldOut && isEnabled && (
        <SaleItemCardTag
          label="SOLD OUT"
          fillColor="#FF3838"
          labelColor="#FFFFFF"
          borderColor="red-border"
        />
      )}
      {!isSoldOut && isEnabled && availableAt > now && (
        <SaleItemCardTag
          label="COMING SOON"
          fillColor="#000000"
          labelColor="#FFFFFF"
          borderColor="gray.500"
        />
      )}
      {!isEnabled && availableAt > now && <AvailableAtTag availableAt={availableAt} />}

      <Card.Body position="relative" zIndex="banner" h="100%" alignItems="flex-start">
        <Flex flex={1} alignItems="end">
          <Card.Title fontSize="30px">{sale?.name ?? itemMetadata?.name}</Card.Title>
        </Flex>
        {isEnabled && (
          <Flex direction="row" mt={{ base: '12px', md: '8px' }}>
            <Flex direction="column">
              <Card.Subtitle fontSize="sm" color="gray.500">
                Price
              </Card.Subtitle>
              <Card.Subtitle fontWeight="semibold" fontSize="22px" color="white">
                {formatMoney(Number(sale?.price))}
              </Card.Subtitle>
            </Flex>
            <Divider
              orientation="vertical"
              bg="gray.500"
              opacity="0.2"
              ml={{ base: '12px', md: '30px' }}
              mr={{ base: '12px', md: '30px' }}
            />
            <Flex direction="column">
              <Card.Subtitle color="gray.500" fontSize="sm">
                Remaining Amount
              </Card.Subtitle>
              <Card.Subtitle fontWeight="semibold" fontSize="22px" color="white">
                {formatNumber(config?.remainingQuantity ?? 0)} / {formatNumber(config?.maxQuantity)}
              </Card.Subtitle>
            </Flex>
            <Divider
              orientation="vertical"
              bg="gray.500"
              opacity="0.2"
              ml={{ base: '12px', md: '30px' }}
              mr={{ base: '12px', md: '30px' }}
            />
            <RarityBadge
              rarity={itemMetadata?.rarity as ItemRarity}
              title={badgeTitle}
              h="34px"
              my="auto"
              sx={{
                span: {
                  fontSize: '14px',
                  fontWeight: 'normal',
                  color: '#8C8C8C',
                },
              }}
            />
          </Flex>
        )}
      </Card.Body>
    </Card.Root>
  )
  return (
    <Skeleton isLoaded={isLoaded}>
      {isEnabled ? (
        <NextLink href={isLoaded ? `/sales/${slug}/sale/${sale?.id}` : ''} passHref>
          {cardContent}
        </NextLink>
      ) : (
        cardContent
      )}
    </Skeleton>
  )
}
