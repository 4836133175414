import { createStandaloneToast } from '@chakra-ui/react'
import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'
import { setCookie } from 'react-use-cookie'
import { ServiceError } from '../lib/api/ServiceError'
import theme from '../theme'
import { User } from '../types/vault/User'
import { useTracking } from './useTracking'
import ServerSideVaultApi from '../lib/api/ServerSideVaultApi'

const toast = createStandaloneToast({
  theme,
  colorMode: 'dark',
})

export const fetchUserServerSide = (token?: string): Promise<User | null> =>
  ServerSideVaultApi.doRequest({
    method: 'GET',
    url: '/market/me',
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  })
    .then((res) => res.json())
    .catch((e: ServiceError) => {
      if (e.code === 401) {
        return null
      }
      throw e
    })

export const fetchUser = (): Promise<User | null> =>
  VaultApi.doRequest({
    method: 'GET',
    url: '/market/me',
  })
    .then((res) => res.json())
    .catch((e: ServiceError) => {
      if (e.code === 401) {
        return null
      }

      if (e.code === 400) {
        toast({
          title: 'Account error',
          description:
            'Something went wrong with your account creation. Please contact customer support to resolve this issue',
          status: 'warning',
          duration: 10000,
        })
      }

      throw e
    })

export const useUser = (options: any = {}) => {
  const { identify } = useTracking()
  return useQuery(['me'], fetchUser, {
    staleTime: 60 * 1000,
    onSuccess: (user) => {
      setCookie('userCountry', user?.on_boarding_country_code ?? '')
      setCookie('userId', user?.id ?? '')
      identify(user?.id)
    },
    retry: (failureCount, err: any) => err?.code !== 400 && failureCount < 3,
    ...options,
  })
}
