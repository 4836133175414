import {
  FormControl,
  Input,
  Text,
  VStack,
  Container,
  HStack,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react'
import { Form as ModalBody, Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { BottomAction, Button } from 'components/atoms'
import { useEmailVerificationStartMutation } from 'hooks/useEmailVerificationStartMutation'
import { useToast } from 'hooks/useToast'
import { useVerifyEmailTwoFactorMutation } from 'hooks/useVerifyEmailTwoFactorMutation'
import { useRemoveEmailTwoFactorMutation } from 'hooks/useRemoveEmailTwoFactorMutation'
import { useValidateEmailTwoFactorMutation } from 'hooks/useValidateEmailTwoFactorMutation'
import { TwoFactorFlowProps } from './types'
import { TwoFactorModalContent } from './TwoFactorModalContent'

const OTPSchema = Yup.object().shape({
  code: Yup.string().length(6).required('Required'),
})

export interface EmailVerificationModalProps extends TwoFactorFlowProps {
  isSetupAllowed: boolean
  onBack?: () => void
  onSwitch?: () => void
  verificationMutation?:
    | typeof useValidateEmailTwoFactorMutation
    | typeof useVerifyEmailTwoFactorMutation
}

export const EmailVerificationModal = ({
  isSetupAllowed = true,
  onClose,
  onBack,
  onVerified,
  skippable = false,
  user,
  isOpen,
  isToRemove2FA,
  removeType,
  setRecoveryCodes,
  skipOnCloseWhenSucceed,
}: EmailVerificationModalProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState(0)

  const { mutate: mutateStart, isLoading: isLoadingVerification } =
    useEmailVerificationStartMutation()
  const { mutate: mutateValidate } = useValidateEmailTwoFactorMutation()
  const { mutate: mutateVerify } = useVerifyEmailTwoFactorMutation()
  const { mutate: mutateRemove } = useRemoveEmailTwoFactorMutation()

  const verificationMutation = user.email2faVerified === 'verified' ? mutateValidate : mutateVerify // TODO: We should refactor this to make it more readable
  const actionMutation = isToRemove2FA ? mutateRemove : verificationMutation

  const toast = useToast()

  const requestOTP = useCallback(() => {
    mutateStart(undefined, {
      onSuccess: () => {
        setRemainingSeconds(60)
      },
      onError: (err: any) => {
        const msg =
          err?.payload?.error ??
          err.message ??
          "We couldn't request code. Please try again after some time."

        toast({
          title: 'Error',
          description: msg,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }, [mutateStart, setRemainingSeconds, toast])

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingSeconds(remainingSeconds - 1)
    }, 1000)
    if (remainingSeconds === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [remainingSeconds, setRemainingSeconds])

  return (
    <>
      <Formik
        validationSchema={OTPSchema}
        initialValues={{ code: '', authType: 'email', removeType: removeType ?? 'email' }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          actionMutation(
            { token: values.code },
            {
              onError: () => {
                toast({
                  title: 'Error',
                  description: `We couldn't validate your code. Please try again.`,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                })
                resetForm()
              },
              onSuccess: (response) => {
                if (response.recoveryCodes && setRecoveryCodes) {
                  setRecoveryCodes(response.recoveryCodes)
                } else {
                  onVerified?.()
                  !skipOnCloseWhenSucceed && onClose()
                }
              },
              onSettled() {
                setSubmitting(false)
              },
            }
          )
        }}
      >
        {({ values, isValid, dirty, submitForm, isSubmitting, setFieldValue, errors, touched }) => (
          <TwoFactorModalContent
            mobileMarginTop={{ base: '174px', md: '0' }}
            desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
            onClose={onClose}
            isOpen={isOpen}
            description="Enter the security code"
            title="Two-Factor Authentication (2FA)"
            innerContent={
              <ModalBody>
                <Container>
                  <VStack spacing="30px" my="8">
                    <Text lineHeight="base" textAlign="center">
                      Click{' '}
                      <Text color="white" as="span" fontWeight="bold">
                        Get Code
                      </Text>{' '}
                      below when you’re ready to receive a verification code via email to the
                      address:
                      <br />
                      <br />
                      <Text color="white" as="span" fontWeight="bold">
                        {user?.email2fa}
                      </Text>
                      <br />
                    </Text>
                    <FormControl isInvalid={!!errors.code && !!touched.code}>
                      <HStack justify="center">
                        <Input
                          maxLength={6}
                          width="auto"
                          id="code"
                          value={values.code}
                          inputMode="numeric"
                          type="tel"
                          textAlign="center"
                          autoComplete="one-time-code"
                          fontSize="18px"
                          lineHeight="22px"
                          fontWeight="400"
                          letterSpacing="0.49em"
                          onChange={(e) => setFieldValue('code', e.target.value)}
                        />
                        <Button
                          ml="2"
                          variant="ghost"
                          colorScheme="orange"
                          color="orange.400"
                          autoFocus
                          fontWeight="400"
                          fontSize="18px"
                          disabled={remainingSeconds !== 0 || isLoadingVerification}
                          onClick={() => requestOTP()}
                        >
                          Get Code
                        </Button>
                      </HStack>
                      <FormErrorMessage>{errors.code}</FormErrorMessage>
                    </FormControl>
                    {remainingSeconds > 0 && (
                      <Text fontSize="sm" color="white">
                        Didn’t receive a code?{' '}
                        <Text as="span" color="orange.400" fontSize="sm">
                          Resend in {remainingSeconds} seconds
                        </Text>
                      </Text>
                    )}
                  </VStack>
                </Container>
              </ModalBody>
            }
            buttons={
              <BottomAction>
                <Flex flexDirection="row" alignItems="center" justifyContent="center" width="full">
                  {skippable && !isSetupAllowed && (
                    <Button
                      alignSelf={isSetupAllowed ? 'flex-start' : 'flex-end'}
                      onClick={onClose}
                      variant="outline"
                      mr={3}
                    >
                      Skip
                    </Button>
                  )}
                  {isSetupAllowed && (
                    <Button colorScheme="gray" mr={3} onClick={onBack} variant="outline">
                      Back
                    </Button>
                  )}
                  <Button
                    isLoading={isSubmitting}
                    variant="light"
                    onClick={submitForm}
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    <span>Submit</span>
                  </Button>
                </Flex>
              </BottomAction>
            }
          />
        )}
      </Formik>
    </>
  )
}
