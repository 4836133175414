import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'
import { Config } from '../types/vault/Config'

const fetchConfig = (): Promise<Config> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/market/config`,
  }).then((res) => res.json())

export const useConfig = () => useQuery('config', fetchConfig)
