import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'
import { ServiceError } from '../lib/api/ServiceError'
import { User } from '../types/vault/User'

interface HOTPAuthPayload {
  token: string
}

interface HOTPVerifyResponse {
  status: User['email2faVerified']
  recoveryCodes: string[]
}

const verifyEmailTwoFactor = ({ token }: HOTPAuthPayload): Promise<HOTPVerifyResponse> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/v2/auth/2fa/email/verify',
    body: {
      token,
    },
  }).then((response) => response.json())

export const useVerifyEmailTwoFactorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<HOTPVerifyResponse, ServiceError, HOTPAuthPayload>(verifyEmailTwoFactor, {
    onSettled: () => {
      queryClient.invalidateQueries('me')
    },
  })
}
