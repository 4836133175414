import {
  Box,
  Collapse,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Flex,
} from '@chakra-ui/react'
import { Avatar, Button, ButtonLink, RequireFeature } from 'components/atoms'
import { useClickOutside } from 'hooks/useClickOutside'
import { useCloseOnNavigation } from 'hooks/useCloseOnNavigation'
import { useFeature } from 'hooks/useFeature'
import { useUser } from 'hooks/useUser'
import { useRef } from 'react'
import { UserMenu } from 'components/templates/layout-components/Navbar/UserMenu'
import { MobileUserMenu } from 'components/templates/layout/MobileMenu'

import Link from 'next/link'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { RequireUser } from '../account'
import { USER_NAV_ITEMS } from '../layout-components/Navbar'

interface AmbassadorLayoutProps {
  children: React.ReactNode
}

export const AmbassadorLayout: React.FC<AmbassadorLayoutProps> = ({ children }) => {
  const { data: user, isLoading } = useUser()
  const { isOpen, onOpen, onClose } = useCloseOnNavigation()
  const userBtnRef = useRef(null)
  const {
    isOpen: isMobileUserOpen,
    onToggle: onToggleMobileUser,
    onClose: onCloseMobileUser,
  } = useDisclosure()

  const [userMenuRef] = useClickOutside(onCloseMobileUser, [userBtnRef])

  const showPreseasonInviteInMenu = useFeature('preseasonInvite')
  const userNavItems = showPreseasonInviteInMenu
    ? USER_NAV_ITEMS
    : USER_NAV_ITEMS.filter((item) => item.url !== '/invite/redeem')

  const toggleMobileUser = () => {
    onToggleMobileUser()
  }

  return (
    <RequireFeature feature="ambassadors">
      <>
        <Head>
          <title key="title">{formatHtmlTitle('Ambassadors')}</title>
        </Head>
        <Flex direction="column" minH="100vh">
          <HStack
            py={4}
            pl={{ base: 4, md: '30px' }}
            pr={{ base: 4, md: '35px' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Link href="/" passHref>
              <Image
                height={{ base: '44px', md: '48px' }}
                src="/images/open-loot-ambassador-logo.svg"
                alt="open loot logo"
                cursor="pointer"
              />
            </Link>

            {!user && !isLoading && (
              <HStack spacing={2}>
                <ButtonLink
                  href="/login"
                  variant="light-outline"
                  display={{ base: 'block', md: 'none' }}
                  w={[20, null, 24, null, '118px']}
                >
                  Sign In
                </ButtonLink>
                <ButtonLink
                  href="/login"
                  variant="ghost"
                  display={{ base: 'none', md: 'block' }}
                  w={[20, null, 24, null, '118px']}
                >
                  Sign In
                </ButtonLink>
                <ButtonLink
                  href="/login"
                  variant="light"
                  w={[20, null, 24, null, '118px']}
                  display={{ base: 'none', md: 'block' }}
                >
                  Sign Up
                </ButtonLink>
              </HStack>
            )}

            {user && (
              <HStack spacing={5}>
                <Box display={{ base: 'none', md: 'block' }}>
                  <Menu isOpen={isOpen} onClose={onClose} placement="bottom-end">
                    <MenuButton
                      rounded="full"
                      variant="none"
                      size="auto"
                      cursor="pointer"
                      minW={0}
                      onClick={onOpen}
                    >
                      <Avatar size={10} name={user.username} />
                    </MenuButton>
                    <MenuList maxW="202px" p={2} bg="white" borderRadius="sm">
                      <UserMenu user={user} px={2.5} navItems={userNavItems} />
                    </MenuList>
                  </Menu>
                </Box>

                <Button
                  ref={userBtnRef}
                  variant="none"
                  size="auto"
                  onClick={toggleMobileUser}
                  display={{ md: 'none' }}
                >
                  <Avatar size={10} name={user.username} />
                </Button>
              </HStack>
            )}
          </HStack>

          <Collapse in={isMobileUserOpen} animateOpacity ref={userMenuRef}>
            {user && (
              <MobileUserMenu onClose={onCloseMobileUser} user={user} navItems={userNavItems} />
            )}
          </Collapse>

          <RequireUser isBlockUnder18User>
            <>{children}</>
          </RequireUser>
        </Flex>
      </>
    </RequireFeature>
  )
}
