import VaultApi from 'lib/api/VaultApi'
import { useMutation, useQueryClient } from 'react-query'

interface UseSignUpForExternalGamesParams {
  slug: string
}

const fetchSignUpForExternalGames = ({ slug }: UseSignUpForExternalGamesParams) =>
  VaultApi.doRequest({
    method: 'POST',
    url: `/v2/market/signup-external/${slug}/signup`,
  }).then((res) => res.json())

export const useSignUpForExternalGameMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(fetchSignUpForExternalGames, {
    onSettled: () => {
      queryClient.invalidateQueries('signups-external-user')
    },
  })
}
